import CompaniesContextWrapper from 'components/CompaniesContextWrapper';
import { ConfigContext } from 'components/ConfigGuard';
import EventCartGuard from 'components/EventCartGuard';
import DonationPaymentPage from 'components/donation/DonationPaymentPage';
import SelectDonationPage from 'components/donation/SelectDonationPage';
import EventsCatalogPage from 'components/events/EventsCatalogPage';
import MainPageLayout from 'components/layout/MainPageLayout';
import LoginPage from 'components/login/LoginPage';
import RedirectPage from 'components/login/RedirectPage';
import MembershipGuard from 'components/MembershipGuard';
import PaymentMethodsContextWrapper from 'components/PaymentMethodsContextWrapper';
import ExpressPaymentPage from 'components/payments/ExpressPaymentPage';
import ProtectedRoute from 'components/shared/ProtectedRoute';
import SubscriptionTermsPage from 'components/subscriptions/SubscriptionTermsPage';
import UserGuard from 'components/UserGuard';
import { tenantConfig } from 'config';
import React, { useContext, useMemo } from 'react';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';
import authClient from 'services/AuthService';
import routes, {
  publicEventsRoutes,
  publicDonationsRoutes,
  publicPreferencesRoutes,
  productsCartRoutes,
  expressPaymentRoutes,
} from 'store/configs/Routes';
import SiteModule from 'store/enums/SiteModule';
import { modulesRequirePayment } from 'util/Route';
import ApplicationPage from 'components/applications/ApplicationPage';
import PreferencesPrivatePage from 'components/profile/PreferencesPrivatePage';
import MemberDirectoryPage from 'components/memberDirectory/MemberDirectoryPage';
import SmacnaFooter from 'components/layout/SmacnaFooter';
import ProductsCartPage from 'components/products/ProductsCartPage';
import ProductCartGuard from 'components/ProductCartGuard';
import EventRegistrationUsersPrintPage from 'components/events/EventRegistrationUsersPrintPage/EventRegistrationUsersPrintPage';
import CommunicationsPreferencesPage from 'components/profile/CommunicationsPreferencesPage/CommunicationsPreferencesPage';
import PublicCommitteePage from 'components/communities/PublicCommitteePage';

const autoLogout = () => {
  if (authClient.isAuthenticated()) {
    authClient.logout('', false);

    return <></>;
  } else {
    return <Redirect to={'/'} />;
  }
};

const checkAuthRoute =
  (children: React.ReactNode) =>
  ({ location }: RouteChildrenProps) => {
    if (tenantConfig.loginUrl) {
      window.location.assign(tenantConfig.loginUrl);
      return <></>;
    }
    if (authClient.isAuthenticated()) {
      if (location.pathname.includes(routes.setPassword)) {
        authClient.logout();
        return children;
      } else {
        return <Redirect to={'/'} />;
      }
    } else {
      return children;
    }
  };

const donationRoutes: React.ReactNode[] = [
  <Route key={'donations-list'} path={routes.donations} exact={true}>
    <SelectDonationPage />
  </Route>,
  <Route key={'donations-pay'} path={`${routes.makeDonation}/:donationId`} exact={true}>
    <DonationPaymentPage />
  </Route>,
];

const AppPublicRoutes: React.FunctionComponent = () => {
  const siteConfig = useContext(ConfigContext);
  const { enabledModules = [], isDefaultTheme, isGamsdTheme, isCompassTheme, isSmacnaTheme } = siteConfig;

  const routesContent: React.ReactNode[] = useMemo(() => {
    if (isDefaultTheme && enabledModules.includes(SiteModule.Donations)) {
      return [
        ...donationRoutes,
        <Route key={'default'} path={'*'} exact={true}>
          <Redirect to={routes.donations} />
        </Route>,
      ];
    } else {
      let publicRoutes: React.ReactNode[] = [
        <Route key={'redirect'} path={routes.redirect} exact={true}>
          <RedirectPage />
        </Route>,
        <Route
          key={'login'}
          path={[
            routes.login,
            `${routes.setPassword}/:username/:requestCode`,
            `${routes.firstLogin}/:username/:tempPassword`,
            `${routes.firstLogin}/:username/`,
          ]}
          exact={true}
        >
          {checkAuthRoute(<LoginPage />)}
        </Route>,
        <Route key={'logout'} path={routes.logout} exact={true}>
          {autoLogout}
        </Route>,
      ];
      let protectedRouteContent: React.ReactNode = <MainPageLayout />;

      if (enabledModules.includes(SiteModule.Events)) {
        publicRoutes = [
          ...publicRoutes,
          <Route key={'events'} path={publicEventsRoutes} exact={true}>
            <EventsCatalogPage />
            {isSmacnaTheme && <SmacnaFooter />}
          </Route>,
        ];
      }
      if (enabledModules.includes(SiteModule.Products)) {
        publicRoutes = [
          ...publicRoutes,
          <Route key={'products-cart'} path={productsCartRoutes} exact={true}>
            <ProductsCartPage />
          </Route>,
        ];
      }
      if (enabledModules.includes(SiteModule.Donations) && (isGamsdTheme || isCompassTheme)) {
        publicRoutes = [
          ...publicRoutes,
          <Route key={'donations'} path={publicDonationsRoutes} exact={true}>
            <DonationPaymentPage />
            {isSmacnaTheme && <SmacnaFooter />}
          </Route>,
        ];
      }
      if (enabledModules.includes(SiteModule.MemberDirectory)) {
        publicRoutes = [
          ...publicRoutes,
          <Route key={'addresses'} path={routes.memberDirectory} exact={true}>
            <MemberDirectoryPage />
            {isSmacnaTheme && <SmacnaFooter />}
          </Route>,
        ];
      }
      if (enabledModules.includes(SiteModule.Committees)) {
        publicRoutes = [
          ...publicRoutes,
          <Route key={'committees'} path={routes.publicCommittees} exact={true}>
            <PublicCommitteePage />
            {isSmacnaTheme && <SmacnaFooter />}
          </Route>,
        ];
      }
      if (enabledModules.includes(SiteModule.DonationsPremium)) {
        publicRoutes = [...donationRoutes, ...publicRoutes];
      }
      if (enabledModules.includes(SiteModule.Membership)) {
        protectedRouteContent = <MembershipGuard>{protectedRouteContent}</MembershipGuard>;
      }
      if (enabledModules.includes(SiteModule.Company)) {
        protectedRouteContent = <CompaniesContextWrapper>{protectedRouteContent}</CompaniesContextWrapper>;
      }
      return [
        ...publicRoutes,
        <ProtectedRoute path={'*'} exact={true} key={'logged-in-routes'}>
          {protectedRouteContent}
        </ProtectedRoute>,
      ];
    }
  }, [isDefaultTheme, enabledModules, isGamsdTheme, isCompassTheme, isSmacnaTheme]);
  const content: React.ReactNode = useMemo(() => {
    let result: React.ReactNode = <Switch>{routesContent}</Switch>;

    if (enabledModules.includes(SiteModule.Events)) {
      result = <EventCartGuard>{result}</EventCartGuard>;
    }
    if (enabledModules.includes(SiteModule.Products)) {
      result = <ProductCartGuard>{result}</ProductCartGuard>;
    }
    if (enabledModules.includes(SiteModule.Membership)) {
      result = <MembershipGuard>{result}</MembershipGuard>;
    }
    if (modulesRequirePayment(enabledModules)) {
      result = <PaymentMethodsContextWrapper>{result}</PaymentMethodsContextWrapper>;
    }
    return result;
  }, [enabledModules, routesContent]);

  return (
    <Switch>
      {enabledModules.includes(SiteModule.Applications) && (
        <Route key={'application-review'} path={`${routes.viewPrivateApplication}/:applicationId`} exact={true}>
          <ApplicationPage />
        </Route>
      )}
      <Route key={'communications-preferences'} path={`${routes.communicationsPreferences}/:id`} exact={true}>
        <CommunicationsPreferencesPage />
      </Route>
      <Route key={'preferences-info-private'} path={publicPreferencesRoutes} exact={true}>
        <PreferencesPrivatePage />
      </Route>
      <Route key={'express-payment'} path={expressPaymentRoutes} exact={true}>
        <ExpressPaymentPage />
      </Route>
      <Route key={'event-registration'} path={`${routes.eventRegistrationUsersPrint}/:id`} exact={true}>
        <EventRegistrationUsersPrintPage />
      </Route>
      {enabledModules.includes(SiteModule.Subscriptions) && (
        <Route key={'subscriptions-terms'} path={routes.subscriptionTerms} exact={true}>
          <SubscriptionTermsPage />
        </Route>
      )}
      <Route path={'*'} exact={true}>
        <UserGuard>{content}</UserGuard>
      </Route>
    </Switch>
  );
};
export default AppPublicRoutes;
