import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Table as MuiTable,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Paper,
  TableContainer,
  Divider,
} from '@mui/material';
import ComponentProps from 'store/types/ComponentProps';
import { SortConfig, SortDirection, TableColumn } from 'store/types/Table';
import { getStringValue } from 'util/Format';
import Spinner from 'components/shared/Spinner';
import { getListByPage } from 'util/Table';

import commonStyles from 'styles/common.module.scss';
import styles from './Table.module.scss';

interface TableRowProps<T> {
  selected: (record: T) => boolean;
}

interface TableProps<T> extends ComponentProps {
  list: T[];
  columns: Array<TableColumn<T>>;
  sort?: SortConfig<T>;
  onSortChange?: (sortConfig: SortConfig<T>) => void;
  rowProps?: TableRowProps<T>;
  itemsPerPage?: number;
  showPagination?: boolean;
  loading?: boolean;
  submitLoading?: boolean;
  cellClassName?: string;
}

type TableComponentType<T = any> = React.FunctionComponent<TableProps<T>>;

const skeletonProps = {
  width: '100%',
  variant: 'text',
  className: styles.skeleton,
  ['data-testid']: 'table-skeleton',
};

const Table: TableComponentType = ({
  list,
  columns,
  sort,
  onSortChange,
  className = '',
  cellClassName = '',
  rowProps,
  itemsPerPage = 10,
  showPagination = false,
  loading = false,
  submitLoading = false,
}) => {
  const [page, setPage] = useState<number>(1);
  const pagesCount = useMemo(() => Math.ceil(list.length / itemsPerPage), [list.length, itemsPerPage]);
  const pageList = useMemo(
    () => (showPagination ? getListByPage(list, itemsPerPage, page) : list),
    [list, showPagination, itemsPerPage, page]
  );

  useEffect(() => {
    setPage(1);
  }, [list]);

  const handleSortChange = useCallback(
    (column: string, direction?: SortDirection) => () => {
      if (onSortChange) {
        const newDirection = !direction ? 'asc' : direction === 'asc' ? 'desc' : undefined;

        onSortChange({ column: newDirection ? column : undefined, direction: newDirection });
      }
    },
    [onSortChange]
  );

  const handlePageChange = useCallback((_: any, newPage: number) => {
    setPage(newPage);
  }, []);

  const headerCells: React.ReactNode[] = useMemo(
    () =>
      columns.map(({ hidden, dataIndex = '', label, align, sortable = false, key }) => {
        const hasSort: boolean = sortable && !!sort && sort.column === dataIndex;
        const sortDirection: SortDirection | undefined = hasSort ? sort && sort.direction : undefined;
        const columnName = dataIndex.toString() || key;
        const testId = `table-cell-${columnName}-header`;

        return (
          <TableCell
            align={align}
            key={testId}
            hidden={hidden}
            data-testid={testId}
            sortDirection={sortDirection || false}
            className={cellClassName}
          >
            <TableSortLabel
              active={hasSort}
              hideSortIcon={!sortable}
              direction={sortDirection}
              onClick={sortable && dataIndex ? handleSortChange(dataIndex.toString(), sortDirection) : undefined}
              data-testid={`${testId}-label`}
            >
              {label}
            </TableSortLabel>
          </TableCell>
        );
      }),
    [sort, cellClassName, handleSortChange, columns]
  );

  const getCells = useCallback(
    (record: any, index: number): React.ReactNode[] =>
      columns.map(({ hidden, render, dataIndex = '', key = '', align, verticalAlign }) => {
        const cellTestId = `table-cell-${dataIndex.toString() || key}-${index}`;

        return (
          <TableCell
            sx={{ verticalAlign }}
            hidden={hidden}
            align={align}
            key={cellTestId}
            data-testid={cellTestId}
            className={cellClassName}
          >
            {render ? render(record[dataIndex], record) : getStringValue(record[dataIndex])}
          </TableCell>
        );
      }),
    [cellClassName, columns]
  );

  const rows: React.ReactNode[] = useMemo(
    () =>
      pageList.map((record: any, index: number) => {
        const tableRowProps = rowProps
          ? {
              role: 'checkbox',
              selected: rowProps.selected(record),
              hover: true,
            }
          : {};
        const rowTestId = `table-row-${index}`;

        return (
          <TableRow
            classes={{
              root: styles.row,
              selected: styles.selected,
            }}
            key={rowTestId}
            data-testid={rowTestId}
            {...tableRowProps}
          >
            {getCells(record, index)}
          </TableRow>
        );
      }),
    [pageList, getCells, rowProps]
  );

  return loading && !submitLoading && !list.length ? (
    <>
      <Skeleton {...(skeletonProps as any)} />
      <Divider />
      <Skeleton {...(skeletonProps as any)} />
      <Divider />
      <Skeleton {...(skeletonProps as any)} />
      <Divider />
    </>
  ) : (
    <Spinner loading={submitLoading || loading}>
      <TableContainer component={Paper} className={classNames(commonStyles.card, className)} data-testid={'table'}>
        <MuiTable size={'medium'}>
          <TableHead>
            <TableRow data-testid={'table-row-header'}>{headerCells}</TableRow>
          </TableHead>
          <TableBody>
            {pageList.length ? (
              rows
            ) : (
              <TableRow key={`no-data-row`}>
                <TableCell align={'center'} colSpan={columns.length} className={cellClassName}>
                  <div className={styles.noData} data-testid={'table-no-data'}>
                    {'No records to display'}
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
        {showPagination && (
          <div className={styles.paginationContainer}>
            <Pagination
              disabled={!list.length}
              showFirstButton={true}
              showLastButton={true}
              count={pagesCount}
              page={page}
              onChange={handlePageChange}
              data-testid={'table-pagination'}
            />
          </div>
        )}
      </TableContainer>
    </Spinner>
  );
};
export default Table;
