import EventCreditItem from 'store/types/events/EventCreditItem';
import { Moment } from 'moment-timezone';
import EventMode from 'store/enums/events/EventMode';
import Person from '@mui/icons-material/Person';
import VideoLabel from '@mui/icons-material/VideoLabel';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import React from 'react';
import EventStatus from 'store/enums/events/EventStatus';
import EventsCartItem from 'store/types/events/EventsCartItem';
import { LibraryBooks } from '@mui/icons-material';
import { EventSessionView, EventSession } from 'store/types/events/Event';
import moment from 'moment';
import { MainEvent, MainEventView } from 'store/types/events/Event';
import EventPrice from 'store/types/events/EventPrice';
import { PaymentFormValues } from 'store/types/FormValues';
import { EventHotelPreferencesData } from 'store/types/events/EventHotelPreferences';
import CollectJSResponse from 'store/types/CollectJSResponse';
import { EventsPaymentData } from 'services/api/PaymentService';
import { getPaymentData } from 'util/Payment';

export const EVENT_DATE_FORMAT = `MMM DD, YYYY, hh:mm A z`;
export const ALL_EVENT_MODES_VALUE = 'All Events';
export const GUEST_PRICE_LEVEL = 'Guest';
export const NON_MEMBER_PRICE_LEVEL = 'Non-Member Price';

export const getFormattedEventDate = (startDate: Moment, endDate: Moment): string => {
  const isSameMonth: boolean =
    !endDate || startDate.clone().startOf('month').unix() === endDate.clone().startOf('month').unix();
  const isSameYear: boolean =
    !endDate || startDate.clone().startOf('year').unix() === endDate.clone().startOf('year').unix();

  return !endDate.isValid() || startDate.isSame(endDate, 'days')
    ? moment(startDate).format('MMM DD, YYYY')
    : !isSameYear
    ? `${moment(startDate).format('MMM DD, YYYY')} - ${moment(endDate).format('MMM DD, YYYY')}`
    : !isSameMonth
    ? `${moment(startDate).format('MMM DD')} - ${moment(endDate).format('MMM DD')}, ${moment(startDate).format('YYYY')}`
    : `${moment(startDate).format('MMM DD')} - ${moment(endDate).format('DD')}, ${moment(startDate).format('YYYY')}`;
};

export const getFormattedEventTime = (startTime: Moment, endTime: Moment, timeZoneName?: string): string => {
  const startDateformat: string = endTime.isValid() ? 'hh:mm A' : 'hh:mm A';

  return `${startTime.utc().format(startDateformat)} ${
    endTime.isValid() ? `- ${endTime.utc().format('hh:mm A')}` : ''
  } ${timeZoneName || ''}`;
};

export const getEventModeIcon = (mode?: EventMode, className?: string): React.ReactNode => {
  switch (mode) {
    case EventMode.InPerson:
      return <Person className={className} />;
    case EventMode.OnDemand:
      return <PlayCircleFilled className={className} />;
    case EventMode.Online:
      return <VideoLabel className={className} />;
    default:
      return <LibraryBooks className={className} />;
  }
};

export const getEventPrice = ({ sessions, price = 0 }: MainEventView): number => {
  let result = price;

  if (sessions?.length) {
    result += sessions.reduce(
      (prevSessionPrice, { status, price = 0 }) => prevSessionPrice + (status === EventStatus.Selected ? price : 0),
      0
    );
  }

  return result;
};

export const getEventPriceLevel = (prices: EventPrice[] = [], userPriceLevelId?: string): number => {
  const guestPrice = prices.find(({ priceLevel }) => priceLevel === GUEST_PRICE_LEVEL)?.unitPrice;
  const nonMemberPrice = prices.find(({ priceLevel }) => priceLevel === NON_MEMBER_PRICE_LEVEL)?.unitPrice;
  const userPriceLevel = prices.find(({ priceLevelId }) => priceLevelId === userPriceLevelId)?.unitPrice;
  const highestPrice: number =
    prices.length &&
    Math.max(
      ...prices.filter(({ priceLevel }) => priceLevel !== GUEST_PRICE_LEVEL).map((priceLevel) => priceLevel.unitPrice)
    );
  const defaultPrice: number = nonMemberPrice ?? highestPrice ?? 0;

  return !userPriceLevelId ? guestPrice ?? defaultPrice : userPriceLevel ?? defaultPrice;
};

export const getTotalPrice = (selectedEvents: MainEventView[] = []): number =>
  selectedEvents.reduce((prevEventPrice, event) => prevEventPrice + getEventPrice(event), 0);

export const getSelectedEvents = (
  cart: EventsCartItem[],
  events: MainEvent[] = [],
  userPriceLevelId: string
): MainEventView[] => {
  let result: MainEventView[] = [];
  const formattedEvents: MainEventView[] = events.map((event) => getMainEventView(event));

  cart.forEach(
    ({
      eventId,
      sessions,
      registrantId,
      customerName,
      priceLevelId,
      companyId,
      guestId,
      companyName,
      registrantName,
    }) => {
      let foundEvent: MainEventView | undefined = formattedEvents.find(({ id }) => eventId === id);

      if (foundEvent) {
        foundEvent = {
          ...foundEvent,
          customerName,
          registrantId,
          companyId,
          guestId,
          companyName,
          registrantName,
          price: getEventPriceLevel(foundEvent.prices, priceLevelId || (!registrantId ? userPriceLevelId : undefined)),
        };
        if (foundEvent.sessions) {
          foundEvent = {
            ...foundEvent,
            sessions: foundEvent.sessions.map((session) => ({
              ...session,
              customerName,
              registrantId,
              companyId,
              guestId,
              companyName,
              registrantName,
              price: getEventPriceLevel(session.prices, priceLevelId || (!registrantId ? userPriceLevelId : undefined)),
              status: sessions?.length && sessions.indexOf(session.id) > -1 ? EventStatus.Selected : session.status,
            })),
          };
        }
        result = [...result, foundEvent];
      }
    }
  );
  return result;
};

export const getMainEventView = (data: MainEvent): MainEventView => ({
  ...data,
  startDate: moment.utc(data.startDate),
  endDate: moment.utc(data.endDate),
  availableUntilDate: data.availableUntilDate ? moment.utc(data.availableUntilDate) : undefined,
  sessions: data.sessions && getEventSessionsView(data.sessions),
});

export const getEventSessionsView = (data: EventSession[]): EventSessionView[] =>
  data.map((item) => ({
    ...item,
    startDate: moment.utc(item.startDate),
    endDate: moment.utc(item.endDate),
  }));

export const getTotalCredits = ({ credits, sessions }: MainEventView): EventCreditItem[] => {
  const result: EventCreditItem[] = credits || [];
  sessions?.forEach(({ credits }) => {
    credits?.forEach((credit) => {
      const currentCreditIndex: number = result.findIndex(({ name }) => credit.name === name);
      if (currentCreditIndex !== -1) {
        result[currentCreditIndex].count += credit.count;
      } else {
        result.push(credit);
      }
    });
  });

  return result;
};

export const getEventsPaymentData = (
  paymentForm: PaymentFormValues,
  selectedEvents: MainEventView[],
  eventsRegistration: EventHotelPreferencesData[],
  totalAmount = 0,
  collectJSResponse?: CollectJSResponse
): EventsPaymentData => {
  const eventRegistrationsData: EventHotelPreferencesData[] = [];
  const getEventRegistration = (event: MainEventView | EventSessionView) => {
    const foundRegistration = eventsRegistration.find(({ eventId, registrantId }) =>
      event.registrantId ? event.registrantId === registrantId && event.id === eventId : event.id === eventId
    );

    if (foundRegistration) {
      return { ...foundRegistration, companyId: event.companyId, guestId: event.guestId };
    } else {
      return {
        eventId: event.id,
        registrantId: event.registrantId,
        companyId: event.companyId,
        guestId: event.guestId,
      };
    }
  };
  selectedEvents.forEach((event) => {
    eventRegistrationsData.push(getEventRegistration(event));
    event.sessions?.length &&
      event.sessions
        .filter(({ status }) => status === EventStatus.Selected)
        .map((event) => eventRegistrationsData.push(getEventRegistration(event)));
  });

  return {
    ...getPaymentData(paymentForm, collectJSResponse),
    totalAmount,
    eventsRegistration: eventRegistrationsData,
  };
};

export const getSelectedEventRegistrationIds = (events: MainEventView[]): string[] =>
  Array.from(
    new Set(
      events
        .map(({ id, sessions }) =>
          sessions?.length
            ? [id, ...sessions.filter(({ status }) => status === EventStatus.Selected).map(({ id }) => id)]
            : id
        )
        .flat()
    )
  );
