import Search from '@mui/icons-material/Search';
import React from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import classNames from 'classnames';
import ComponentProps from 'store/types/ComponentProps';
import { InputProps } from 'util/Form';

import styles from './SearchInput.module.scss';

interface SearchInputProps extends ComponentProps, Pick<TextFieldProps, 'value' | 'onChange' | 'placeholder' | 'size'> {
  inputProps?: InputProps;
  loading?: boolean;
  inputClassName?: string;
}

const SearchInput: React.FunctionComponent<SearchInputProps> = ({
  value,
  onChange,
  placeholder = '',
  className = '',
  inputClassName = '',
  loading = false,
  size = 'small',
}) => (
  <TextField
    data-testid={'search-input'}
    disabled={loading}
    size={size}
    placeholder={placeholder || 'Start typing to search...'}
    value={value}
    className={classNames(styles.searchInput, className)}
    onChange={onChange}
    InputProps={{
      className: classNames(styles.input, inputClassName),
      startAdornment: (
        <InputAdornment position={'start'}>
          <Search />
        </InputAdornment>
      ),
    }}
    inputProps={{ 'data-testid': 'input' }}
  />
);

export default SearchInput;
