import React, { useCallback } from 'react';
import { Button, Link, Tooltip } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ButtonProps } from '@mui/material/Button/Button';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';

import styles from './SocialShareButtons.module.scss';

const buttonProps: ButtonProps = {
  color: 'primary',
  variant: 'outlined',
  className: styles.button,
};

interface SocialShareButtonsProps extends Pick<ButtonProps, 'size'> {
  shareLink: string;
}

const SocialShareButtons: React.FunctionComponent<SocialShareButtonsProps> = ({ shareLink, size = 'small' }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleButtonClick = useCallback(() => {
    const textField = document.createElement('textarea');
    textField.innerText = shareLink;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    enqueueSnackbar('Link copied successfully', {
      variant: 'info',
    });
  }, [enqueueSnackbar, shareLink]);

  return (
    <div
      className={classNames({
        [styles.largeSize]: size === 'large',
        [styles.mediumSize]: size === 'medium',
        [styles.smallSize]: size === 'small',
      })}
    >
      <Tooltip title={'Share on Facebook'} arrow={true} placement={'top'}>
        <Button {...buttonProps} size={size}>
          <Link
            className={styles.link}
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`}
            target={'_blank'}
          >
            <FacebookIcon />
          </Link>
        </Button>
      </Tooltip>
      <Tooltip title={'Share on Twitter'} arrow={true} placement={'top'}>
        <Button {...buttonProps} size={size}>
          <Link
            className={styles.link}
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareLink)}`}
            target={'_blank'}
          >
            <TwitterIcon />
          </Link>
        </Button>
      </Tooltip>
      <Tooltip title={'Share by Email'} arrow={true} placement={'top'}>
        <Button {...buttonProps} size={size}>
          <Link className={styles.link} href={`mailto:?body=${encodeURIComponent(shareLink)}`} target={'_blank'}>
            <EmailIcon />
          </Link>
        </Button>
      </Tooltip>
      <Tooltip title={'Copy Link'} arrow={true} placement={'top'}>
        <Button {...buttonProps} size={size} onClick={handleButtonClick}>
          <ContentCopyIcon />
        </Button>
      </Tooltip>
    </div>
  );
};
export default SocialShareButtons;
