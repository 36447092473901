import { httpRequestWrapper } from 'util/Request';
import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { MemberDirectorySearchFilter, MemberDirectoryUser } from 'store/types/MemberDirectory';

type MemberDirectoryUserResponseDate = MemberDirectoryUser[];
type SearchFiltersResponseData = MemberDirectorySearchFilter[];

class MemberDirectoryService {
  public static searchMember(
    keyword: string,
    cityOrZip: string,
    type: string,
    filters: string
  ): Promise<MemberDirectoryUserResponseDate> {
    return new Promise<MemberDirectoryUserResponseDate>(
      (resolve: (data: MemberDirectoryUserResponseDate) => void, reject: (error: string) => void) => {
        httpRequestWrapper<MemberDirectoryUserResponseDate>(
          HttpService.get(`${API_URL}/memberdirectory/search`, {
            queryParams: { keyword, cityOrZip, type, filters },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getSearchFilters(type: string): Promise<SearchFiltersResponseData> {
    return new Promise<SearchFiltersResponseData>(
      (resolve: (data: SearchFiltersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<SearchFiltersResponseData>(
          HttpService.get(`${API_URL}/memberdirectory/filters`, {
            queryParams: { type },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}

export default MemberDirectoryService;
