import { Button, Checkbox, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import ViewCompanyUserModal from 'components/company/CompanyUsersSection/ViewCompanyUserModal';
import Table from 'components/shared/Table';
import React, { Reducer, useCallback, useEffect, useReducer } from 'react';
import CompanyUser from 'store/types/CompanyUser';
import { SortConfig, TableColumn } from 'store/types/Table';
import UseRequestData from 'store/types/UseRequestData';
import { EMPTY_STRING_VALUE, getEmailLink, getUserFullName } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { userFullNameSortLabel } from 'util/Table';
import reducer, {
  CompanyEmployeesTableAction,
  CompanyEmployeesTableActionType,
  CompanyEmployeesTableState,
  initialState,
  TableCompanyEmployee,
} from './CompanyEmployeesTableReducer';

import StatusLabel from 'components/shared/StatusLabel';
import commonStyles from 'styles/common.module.scss';

interface CompanyEmployeesTableProps extends UseRequestData<CompanyUser[]> {
  companyId?: string;
  isSelectTable?: boolean;
  onSelectEmployees?: (employees: CompanyUser[]) => void;
  selectedEmployees?: CompanyUser[];
}

const CompanyEmployeesTable: React.FunctionComponent<CompanyEmployeesTableProps> = ({
  companyId,
  data,
  loading,
  error,
  refetch,
  isSelectTable,
  onSelectEmployees,
  selectedEmployees = [],
}) => {
  const [{ sort, list = [], selectedUser }, dispatch] = useReducer<
    Reducer<CompanyEmployeesTableState, CompanyEmployeesTableAction>
  >(reducer, initialState);

  useEffect(() => {
    if (data) {
      dispatch({
        type: CompanyEmployeesTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  const handleViewModalOpen = useCallback(
    (record) => () => {
      dispatch({
        type: CompanyEmployeesTableActionType.OpenViewUserModal,
        payload: { selectedUser: record },
      });
    },
    []
  );

  const handleViewModalClose = useCallback(() => {
    dispatch({ type: CompanyEmployeesTableActionType.CloseViewUserModal, payload: {} });
  }, []);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({
      type: CompanyEmployeesTableActionType.UpdateSort,
      payload: { sort: newSort },
    });
  }, []);

  const isRowSelected = useCallback(
    (employee: CompanyUser) => !!selectedEmployees.find(({ id }: CompanyUser) => id === employee.id),
    [selectedEmployees]
  );

  const handleCheckboxClick = useCallback(
    (employee: CompanyUser) => () => {
      if (onSelectEmployees) {
        let result: CompanyUser[] = selectedEmployees;
        const isEmployeeSelected = !!result?.find(({ id }) => id === employee.id);

        if (isEmployeeSelected) {
          result = result?.filter(({ id }) => id !== employee.id);
        } else {
          result = [...result, employee];
        }

        onSelectEmployees(result);
      }
    },
    [onSelectEmployees, selectedEmployees]
  );

  const handleSelectAllCheckboxes = useCallback(() => {
    const isAllEmployeesSelected: boolean = selectedEmployees?.length === list.length;

    if (onSelectEmployees) {
      onSelectEmployees(isAllEmployeesSelected ? [] : list);
    }
  }, [list, onSelectEmployees, selectedEmployees]);

  const columns: Array<TableColumn<TableCompanyEmployee>> = [
    {
      key: 'checkbox',
      hidden: !isSelectTable,
      label: <Checkbox color={'primary'} onChange={handleSelectAllCheckboxes} />,
      align: 'center',
      render: (_: any, record: CompanyUser) =>
        record.isUserRegistered ? (
          <StatusLabel theme={'grey'} status={'Registered'} />
        ) : (
          <Checkbox color={'primary'} checked={isRowSelected(record)} onChange={handleCheckboxClick(record)} />
        ),
    },
    {
      dataIndex: userFullNameSortLabel,
      label: 'Name',
      sortable: true,
      render: (_: any, record: TableCompanyEmployee) => getUserFullName(record, true),
    },
    {
      dataIndex: 'title',
      label: 'Job title',
      sortable: true,
      render: (title: string) => (title ? title : EMPTY_STRING_VALUE),
    },
    {
      dataIndex: 'email',
      label: 'Email',
      sortable: true,
      render: (email: string) => (email ? getEmailLink(email) : EMPTY_STRING_VALUE),
    },
    {
      dataIndex: 'entityId',
      label: 'Customer ID',
      align: 'center',
      sortable: true,
    },
    {
      label: 'Action',
      align: 'center',
      hidden: isSelectTable,
      render: (_: any, record: TableCompanyEmployee) => (
        <Button size={'small'} onClick={handleViewModalOpen(record)} variant={'outlined'}>
          {'Edit'}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Grid {...defaultGridContainerProps}>
        {error ? (
          <Grid {...defaultGridItemProps}>
            <Alert severity={'error'} className={commonStyles.alert}>
              {error}
            </Alert>
          </Grid>
        ) : (
          <Grid {...defaultGridItemProps}>
            <Table
              columns={columns}
              list={list}
              sort={sort}
              onSortChange={handleSortChange}
              loading={loading}
              showPagination={true}
            />
          </Grid>
        )}
      </Grid>
      {selectedUser && companyId && (
        <ViewCompanyUserModal
          open={!!selectedUser}
          onClose={handleViewModalClose}
          data={selectedUser}
          refetch={refetch}
          companyId={companyId}
        />
      )}
    </>
  );
};
export default CompanyEmployeesTable;
