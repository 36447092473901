import moment from 'moment-timezone';
import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { defaultGridItemProps } from 'util/Layout';
import AddressFormSection from 'components/shared/AddressFormSection';
import PhoneFormItem from 'components/profile/PhoneFormItem';
import EmailFormItem from 'components/profile/EmailFormItem';
import UserNameFormSection from 'components/profile/UserNameFormSection';
import { ConfigContext } from 'components/ConfigGuard';
import DateFormItem from 'components/shared/DateFormItem';

interface ProfileFormProps {
  currentUserForm?: boolean;
  disabled?: boolean;
}

const ProfileForm: React.FunctionComponent<ProfileFormProps> = ({ currentUserForm = false, disabled }) => {
  const { isNccerTheme, isSnaTheme } = useContext(ConfigContext);

  return (
    <>
      <UserNameFormSection
        middleNameVisible={true}
        lastNameEnabled={!(isNccerTheme && currentUserForm)}
        disabled={disabled}
      />
      {isNccerTheme && (
        <Grid {...defaultGridItemProps} md={5}>
          <DateFormItem
            fieldName={'birthDate'}
            label={'Date of Birth'}
            maxDate={moment(new Date()).startOf('day')}
            required={false}
            disabled={disabled}
          />
        </Grid>
      )}
      <AddressFormSection required={!isNccerTheme} disabled={disabled} />
      <Grid {...defaultGridItemProps} md={4}>
        <PhoneFormItem phoneFieldName={'cellPhone'} label={'Cell Phone Number'} disabled={disabled} />
      </Grid>
      {!isSnaTheme && (
        <Grid {...defaultGridItemProps} md={4}>
          <PhoneFormItem
            required={isNccerTheme}
            label={isNccerTheme ? 'Mobile Number' : undefined}
            disabled={disabled}
          />
        </Grid>
      )}
      <Grid {...defaultGridItemProps} md={4}>
        <EmailFormItem currentUserEmail={currentUserForm} disabled={disabled} />
      </Grid>
    </>
  );
};
export default ProfileForm;
