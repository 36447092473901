import User, { PublicUser } from 'store/types/User';
import { HttpError, HttpService } from 'services/HttpService';
import { API_URL, PROFILE_API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import JobFunction from 'store/types/JobFunction';
import { httpRequestWrapper } from 'util/Request';
import SiteConfig, { ReportsConfig, SiteParameters } from 'store/types/SiteConfig';
import { CompanyRequestsInfo, NotifyingCompaniesData } from 'store/types/Company';
import UserPermissions from 'store/types/UserPermissions';

type JobFunctionResponseData = JobFunction[];
type UserPermissionsResponseData = UserPermissions;
type UsersSearchResponseData = PublicUser[];

class UserService {
  public static getCurrentUser(): Promise<User> {
    return new Promise<User>((resolve: (data: User) => void, reject: (error: HttpError) => void) => {
      HttpService.get(`${PROFILE_API_URL}/profiles`, {
        headers: {
          TenantId: tenantConfig.id,
          authorization: AuthService.getBearerToken(),
        },
      })
        .toPromise()
        .then((data: User) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  public static getEmployerRequestsCount(): Promise<CompanyRequestsInfo> {
    return new Promise<CompanyRequestsInfo>(
      (resolve: (value: CompanyRequestsInfo) => void, reject: (error: string) => void) => {
        httpRequestWrapper(
          HttpService.get(`${PROFILE_API_URL}/profiles/employer/requests/count`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateCurrentUserImage(imageFile: File): Promise<string> {
    const data = new FormData();

    data.append('imageFile', imageFile);
    return new Promise<string>((resolve: (logoUrl: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.post(`${PROFILE_API_URL}/profiles/logo`, {
          data,
          headers: {
            Accept: 'application/json',
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getJobFunctionList(): Promise<JobFunctionResponseData> {
    return new Promise<JobFunctionResponseData>(
      (resolve: (data: JobFunctionResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<JobFunctionResponseData>(
          HttpService.get(`${API_URL}/configuration/functions`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getUserPermissions(): Promise<UserPermissionsResponseData> {
    return new Promise<UserPermissionsResponseData>(
      (resolve: (data: UserPermissionsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<UserPermissionsResponseData>(
          HttpService.get(`${API_URL}/configuration/permissions`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getSiteConfig(): Promise<SiteConfig> {
    return new Promise<SiteConfig>((resolve: (data: SiteConfig) => void, reject: (error: string) => void) => {
      httpRequestWrapper<SiteConfig>(
        HttpService.get(`${API_URL}/configuration/site`, {
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getSiteParameters(): Promise<SiteParameters> {
    return new Promise<SiteParameters>((resolve: (data: SiteParameters) => void, reject: (error: string) => void) => {
      httpRequestWrapper<SiteParameters>(
        HttpService.get(`${API_URL}/configuration/parameters`, {
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getReportsConfig(): Promise<ReportsConfig> {
    return new Promise<ReportsConfig>((resolve: (data: ReportsConfig) => void, reject: (error: string) => void) => {
      httpRequestWrapper<ReportsConfig>(
        HttpService.get(`${API_URL}/configuration/reports`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateCurrentUserEmail(email: string): Promise<string> {
    return new Promise<string>((resolve: (newEmail: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.put(`${API_URL}/account/email`, {
          data: { email },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateCurrentUserPassword(oldPassword: string, newPassword: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/account/password`, {
          data: { oldPassword, newPassword, authToken: AuthService.getAccessToken() },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static searchUser(name: string, excludeCustomer?: string): Promise<UsersSearchResponseData> {
    return new Promise<UsersSearchResponseData>(
      (resolve: (data: UsersSearchResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<UsersSearchResponseData>(
          HttpService.get(`${PROFILE_API_URL}/profiles/search`, {
            queryParams: { name, excludeCustomer },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateEmailNotificationsFlag(flag: boolean): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${PROFILE_API_URL}/profiles/emails`, {
          queryParams: { state: flag },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getNotifyingCompanies(): Promise<NotifyingCompaniesData> {
    return new Promise<NotifyingCompaniesData>(
      (resolve: (data: NotifyingCompaniesData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<NotifyingCompaniesData>(
          HttpService.get(`${PROFILE_API_URL}/profiles/emails`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}
export default UserService;
