import React, { useContext, useState } from 'react';
import { UserContext } from 'components/UserGuard';
import { ConfigContext } from 'components/ConfigGuard';
import { Button, Divider, Grid, IconButton, Link } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { getUserFullName } from 'util/Format';
import { tenantConfig } from 'config';
import UserService from 'services/api/UserService';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import UploadImageModal from 'components/shared/UploadImageModal';
import { getHashRouteUrl } from 'util/Route';
import routes from 'store/configs/Routes';
import Avatar from 'components/shared/Avatar';
import Card from 'components/shared/Card';

import styles from './EditProfilePageView.module.scss';
import commonStyles from 'styles/common.module.scss';

const EditProfilePageView: React.FunctionComponent = () => {
  const { editProfileUrl = '' } = tenantConfig;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const { isNccerTheme } = useContext(ConfigContext);
  const currentUser = useContext(UserContext);
  const {
    nccerCardNumber,
    logoUrl,
    id,
    title,
    parentOrCustomCompanyName,
    biography,
    birthDate,
    nickname,
    cellPhone,
    phone,
    email,
    setCurrentUser,
    parentCompanyId,
    userLoading,
    entityId,
    professionalDesignation,
  } = currentUser;

  const handleUploadImageOpen = () => {
    setUploadModalOpen(true);
  };

  const handleUploadImageClose = () => {
    setUploadModalOpen(false);
  };

  const handleUploadImageSubmit = (images: File[]) => {
    setLoading(true);
    UserService.updateCurrentUserImage(images[0])
      .then((newLogoUrl: string) => {
        enqueueSnackbar('Image successfully updated', { variant: 'success' });
        setUploadModalOpen(false);
        setCurrentUser({ logoUrl: newLogoUrl });
      })
      .catch((error: string) => {
        enqueueSnackbar(error, defaultSnackbarErrorProps);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Grid {...defaultGridContainerProps} className={styles.profile}>
        <Grid {...defaultGridItemProps} className={styles.nameSection} md={10}>
          <div className={styles.userTitle}>
            <h2>{getUserFullName(currentUser, true)}</h2>
            {isNccerTheme ? (
              nccerCardNumber ? (
                <p className={styles.id}>{`NCCER Card Number: ${nccerCardNumber}`}</p>
              ) : null
            ) : (
              <p className={styles.id}>{`Customer ID: ${entityId}`}</p>
            )}
          </div>
          <Divider className={styles.divider} />
          <Grid {...defaultGridContainerProps} spacing={2}>
            {(nickname || birthDate || title || parentOrCustomCompanyName) && (
              <Grid {...defaultGridItemProps} md={6}>
                <Card
                  className={styles.card}
                  spinnerClassName={styles.spinner}
                  title={<h3>{'Personal Information'}</h3>}
                >
                  {title && (
                    <p className={commonStyles.text}>
                      <b>{`Title: `}</b> <span>{`${title}`}</span>
                    </p>
                  )}
                  {parentOrCustomCompanyName && (
                    <p className={commonStyles.text}>
                      {parentCompanyId ? (
                        <>
                          <b>{`Employer: `}</b> <span>{`${parentOrCustomCompanyName}`}</span>
                        </>
                      ) : (
                        <>
                          <b>{`Company Name: `}</b> <span>{`${parentOrCustomCompanyName}`}</span>
                        </>
                      )}
                    </p>
                  )}
                  {nickname && (
                    <p className={commonStyles.text}>
                      <b>{`Nickname: `}</b> <span>{`${nickname}`}</span>
                    </p>
                  )}
                  {birthDate && (
                    <p className={commonStyles.text}>
                      <b>{`BirthDate: `}</b> <span>{`${birthDate}`}</span>
                    </p>
                  )}
                  {professionalDesignation && (
                    <p className={commonStyles.text}>
                      <b>{`Professional Designation: `}</b> <span>{`${professionalDesignation}`}</span>
                    </p>
                  )}
                </Card>
              </Grid>
            )}
            <Grid {...defaultGridItemProps} md={nickname || birthDate || title || parentOrCustomCompanyName ? 6 : 12}>
              <Card className={styles.card} spinnerClassName={styles.spinner} title={<h3>{'Contact Information'}</h3>}>
                {phone && (
                  <p className={commonStyles.text}>
                    <b>{`Phone: `}</b> <span>{`${phone}`}</span>
                  </p>
                )}
                {cellPhone && (
                  <p className={commonStyles.text}>
                    <b>{`Mobile: `}</b> <span>{`${cellPhone}`}</span>
                  </p>
                )}
                {email && (
                  <>
                    <p className={commonStyles.text}>
                      <b>{`Email: `}</b> <span>{`${email}`}</span>
                    </p>
                    <span className={styles.helperText}>
                      {'Your email address is used as account login. To edit, please to go '}
                      <Link href={getHashRouteUrl(routes.settingsAccount)} underline={'always'} color={'inherit'}>
                        {'Account Settings'}
                      </Link>
                      .
                    </span>
                  </>
                )}
              </Card>
            </Grid>
            {biography && (
              <Grid {...defaultGridItemProps}>
                <Card title={<h3>{'Biography'}</h3>}>
                  <p className={commonStyles.text}>{biography}</p>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid {...defaultGridItemProps} md={2}>
          <div className={styles.avatar}>
            <Avatar className={styles.avatarImage} src={logoUrl} loading={userLoading} darkBackground={true} />
            <IconButton className={styles.editButton} color={'primary'} onClick={handleUploadImageOpen}>
              <EditIcon className={styles.editButtonIcon} />
            </IconButton>
          </div>
        </Grid>
        {editProfileUrl && (
          <Grid {...defaultGridItemProps}>
            <Button
              color={'secondary'}
              variant={'contained'}
              type={'submit'}
              href={editProfileUrl + encodeURIComponent(id)}
            >
              {'Edit Profile'}
            </Button>
          </Grid>
        )}
      </Grid>
      <UploadImageModal
        open={uploadModalOpen}
        loading={loading}
        onClose={handleUploadImageClose}
        onSubmit={handleUploadImageSubmit}
      />
    </>
  );
};

export default EditProfilePageView;
