import React, { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import Spinner from 'components/shared/Spinner';
import { SiteParameters } from 'store/types/SiteConfig';
import UserService from 'services/api/UserService';
import { Alert } from '@mui/material';
import commonStyles from 'styles/common.module.scss';

const getDefaultStaticContext = (data?: SiteParameters): SiteParameters => ({
  payments: {
    amountLimitCC: data?.payments?.amountLimitCC,
    amountLimitACH: data?.payments?.amountLimitACH,
  },
  partner: {
    fundReconciliationReportApplicationId: data?.partner?.fundReconciliationReportApplicationId || '',
    fundRequestApplicationId: data?.partner?.fundRequestApplicationId || '',
    campaignsSectionHeader: data?.partner?.campaignsSectionHeader || '',
    partnerDashboardTabHeader: data?.partner?.partnerDashboardTabHeader || '',
    campaignDetailsTabHeader: data?.partner?.campaignDetailsTabHeader || '',
  },
  applications: {
    applicationButtonLabel: data?.applications?.applicationButtonLabel || '',
    pendingActionsTableHeader: data?.applications?.pendingActionsTableHeader || '',
    submittedApplicationsTableHeader: data?.applications?.submittedApplicationsTableHeader || '',
  },
  profile: {
    profileInfoHeader: data?.profile?.profileInfoHeader || '',
    additionalInfoHeader: data?.profile?.additionalInfoHeader || '',
    manageMyAddressHeader: data?.profile?.manageMyAddressHeader || '',
    relationshipHeader: data?.profile?.relationshipHeader || '',
    activeEmployerTableHeader: data?.profile?.activeEmployerTableHeader || '',
    incomingEmployerRequestsTableHeader: data?.profile?.incomingEmployerRequestsTableHeader || '',
    outgoingEmployerRequestsTableHeader: data?.profile?.outgoingEmployerRequestsTableHeader || '',
    myEmployerHeader: data?.profile?.myEmployerHeader || '',
    updateEmployerModalHeader: data?.profile?.updateEmployerModalHeader || '',
    addNewSubListItemButtonName: data?.profile?.addNewSubListItemButtonName || '',
    advocacyHeader: data?.profile?.advocacyHeader || '',
    priorApprovalsTableHeader: data?.profile?.priorApprovalsTableHeader || '',
    submitPriorApprovalModalHeader: data?.profile?.submitPriorApprovalModalHeader || '',
    communicationsPreferencesHeader: data?.profile?.communicationsPreferencesHeader || '',
    digitalSignatureAdvocacyHeader: data?.profile?.digitalSignatureAdvocacyHeader || '',
    titleAdvocacyHeader: data?.profile?.titleAdvocacyHeader || '',
    authorizedAdvocacyYearsHeader: data?.profile?.authorizedAdvocacyYearsHeader || '',
    createNewContactRelationshipsHeader: data?.profile?.createNewContactRelationshipsHeader || '',
  },
  companyManagement: {
    companyMgtHeader: data?.companyManagement?.companyMgtHeader || '',
    marketSegmentHeader: data?.companyManagement?.marketSegmentHeader || '',
    adminsTableHeader: data?.companyManagement?.adminsTableHeader || '',
    companyAddressHeader: data?.companyManagement?.companyAddressHeader || '',
    companyInformationHeader: data?.companyManagement?.companyInformationHeader || '',
    companyAdditionInformationHeader: data?.companyManagement?.companyAdditionInformationHeader || '',
    companyProfileHeader: data?.companyManagement?.companyProfileHeader || '',
    employeesTableHeader: data?.companyManagement?.employeesTableHeader || '',
    searchEmployeeModalHeader: data?.companyManagement?.searchEmployeeModalHeader || '',
    searchAdminModalHeader: data?.companyManagement?.searchAdminModalHeader || '',
    addEmployeeModalHeader: data?.companyManagement?.addEmployeeModalHeader || '',
    addAdminModalHeader: data?.companyManagement?.addAdminModalHeader || '',
    outgoingEmployeeAccessTableHeader: data?.companyManagement?.outgoingEmployeeAccessTableHeader || '',
    incomingEmployeeAccessTableHeader: data?.companyManagement?.incomingEmployeeAccessTableHeader || '',
    companyDropdownName: data?.companyManagement?.companyDropdownName || '',
    companyRequestsHeader: data?.companyManagement?.companyRequestsHeader || '',
    inactivePriorApprovalTableHeader: data?.companyManagement?.inactivePriorApprovalTableHeader || '',
    activePriorApprovalTableHeader: data?.companyManagement?.activePriorApprovalTableHeader || '',
    companyAdvocacyHeader: data?.companyManagement?.companyAdvocacyHeader || '',
  },
  events: {
    sessionSelectionModalHeader: data?.events?.sessionSelectionModalHeader || '',
    addGuestModalHeader: data?.events?.addGuestModalHeader || '',
    hideEventTypeFilter: data?.events?.hideEventTypeFilter,
    hideCreditTypeFilter: data?.events?.hideCreditTypeFilter,
    multiSessionLabelName: data?.events?.multiSessionLabelName || '',
    groupRegistrationEmployeeSelectionModalHeader: data?.events?.groupRegistrationEmployeeSelectionModalHeader || '',
    groupRegistrationSessionSelectionModalHeader: data?.events?.groupRegistrationSessionSelectionModalHeader || '',
    paymentConfirmationModalHeader: data?.events?.paymentConfirmationModalHeader || '',
  },
  myEvents: {
    upcomingEventsTabHeader: data?.myEvents?.upcomingEventsTabHeader || '',
    completedEventsTabHeader: data?.myEvents?.completedEventsTabHeader || '',
    registrationCutoffMessage: data?.myEvents?.registrationCutoffMessage || '',
  },
  products: {
    returnButtonLabel: data?.products?.returnButtonLabel || '',
    returnButtonLink: data?.products?.returnButtonLink || '',
  },
  settings: {
    accountSettingsTabHeader: data?.settings?.accountSettingsTabHeader || '',
    manageSavedPaymentTabHeader: data?.settings?.manageSavedPaymentTabHeader || '',
  },
  support: {
    submittedSupportCasesTableHeader: data?.support?.submittedSupportCasesTableHeader || '',
  },
  subscriptions: {
    subscriptionsPageHeader: data?.subscriptions?.subscriptionsPageHeader || '',
  },
  personalPayments: {
    openInvoicesTableHeader: data?.personalPayments?.openInvoicesTableHeader || '',
    billingHistoryTableHeader: data?.personalPayments?.billingHistoryTableHeader || '',
    outstandingInvoicesTableHeader: data?.personalPayments?.outstandingInvoicesTableHeader || '',
    billingHistoryTabHeader: data?.personalPayments?.billingHistoryTabHeader || '',
    payMyInvoicesTabHeader: data?.personalPayments?.payMyInvoicesTabHeader || '',
    payMySalesOrdersTabHeader: data?.personalPayments?.payMySalesOrdersTabHeader || '',
  },
  companyPayments: {
    openInvoicesTableHeader: data?.companyPayments?.openInvoicesTableHeader || '',
    billingHistoryTableHeader: data?.companyPayments?.billingHistoryTableHeader || '',
    outstandingInvoicesTableHeader: data?.companyPayments?.outstandingInvoicesTableHeader || '',
  },
  groupPayments: {
    billingHistoryTabHeader: data?.groupPayments?.billingHistoryTabHeader || '',
    openInvoicesTableHeader: data?.groupPayments?.openInvoicesTableHeader || '',
    billingHistoryTableHeader: data?.groupPayments?.billingHistoryTableHeader || '',
    payMyInvoicesTabHeader: data?.groupPayments?.payMyInvoicesTabHeader || '',
    manageAccessTabHeader: data?.groupPayments?.manageAccessTabHeader || '',
  },
  donations: {
    donationsPageHeader: data?.donations?.donationsPageHeader || '',
  },
  membership: {
    membershipPageHeader: data?.membership?.membershipPageHeader || '',
  },
  committees: {
    myCommitteesTabHeader: data?.committees?.myCommitteesTabHeader || '',
    pendingCommitteesTabHeader: data?.committees?.pendingCommitteesTabHeader || '',
    pastCommitteesTabHeader: data?.committees?.pastCommitteesTabHeader || '',
    relevantWorkExperienceHeader: data?.committees?.relevantWorkExperienceHeader || '',
    joinCommitteeReasonHeader: data?.committees?.joinCommitteeReasonHeader || '',
  },
  chapters: {
    currentChaptersTabHeader: data?.chapters?.currentChaptersTabHeader || '',
    pastChaptersTabHeader: data?.chapters?.pastChaptersTabHeader || '',
  },
  clientSettings: {
    supportEmail: data?.clientSettings?.supportEmail || '',
  },
});

export const ParametersContext = React.createContext<SiteParameters>({} as SiteParameters);

const ParametersGuard: React.FunctionComponent = ({ children }) => {
  const [staticData, setStaticData] = useState<SiteParameters>(getDefaultStaticContext());
  const { data, loading, error } = useRequest<SiteParameters>(UserService.getSiteParameters);

  useEffect(() => {
    if (data) {
      setStaticData(getDefaultStaticContext(data));
    }
  }, [data]);

  return loading ? (
    <Spinner loading={true} fullPage={true} transparent={false} />
  ) : error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <ParametersContext.Provider value={staticData}>{children}</ParametersContext.Provider>
  );
};
export default ParametersGuard;
