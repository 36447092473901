import React, { useEffect, useMemo, useState } from 'react';
import { Container } from '@mui/material';
import styles from 'components/profile/PreferencesPrivatePage/PreferencesPrivatePage.module.scss';
import Spinner from 'components/shared/Spinner';
import Alert from '@mui/material/Alert';
import useRequest from 'hooks/useRequest';
import { useParams } from 'react-router';
import PreferencesService from 'services/api/PreferencesService';
import CommunicationsPreferences from 'store/types/CommunicationsPreferences';
import CommunicationsPreferencesPageView from 'components/profile/CommunicationsPreferencesPageView/CommunicationsPreferencesPageView';
import { getPreferencesQuestionsView, PREFERENCES_FIELD_NAME } from 'util/Preferences';
import { PreferenceQuestionView } from 'store/types/PreferenceQuestion';

import commonStyles from 'styles/common.module.scss';

const CommunicationsPreferencesPage: React.FunctionComponent = () => {
  const { id = '' } = useParams<{ id: string }>();
  const communicationsPreferencesRequest = useMemo(
    () => (id ? () => PreferencesService.getCommunicationsPreferences(id) : undefined),
    [id]
  );
  const { data, error, loading } = useRequest<CommunicationsPreferences>(communicationsPreferencesRequest);
  const [questions, setQuestions] = useState<PreferenceQuestionView[] | undefined>(undefined);

  useEffect(() => {
    if (data) {
      setQuestions(getPreferencesQuestionsView(data.preferences, PREFERENCES_FIELD_NAME));
    }
  }, [data]);

  return (
    <Container maxWidth={'xl'} className={styles.container}>
      <Spinner loading={loading}>
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : (
          data && questions && <CommunicationsPreferencesPageView questions={questions} data={data} id={id} />
        )}
      </Spinner>
    </Container>
  );
};

export default CommunicationsPreferencesPage;
