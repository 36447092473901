import { Button, Divider, Grid, MenuItem, TextField } from '@mui/material';
import Modal from 'components/shared/Modal';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { defaultFormProps, getAddressFormValues, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { ModalProps } from 'store/types/ComponentProps';
import CompanyService from 'services/api/CompanyService';
import { useSnackbar } from 'notistack';
import UserNameFormSection from 'components/profile/UserNameFormSection';
import EmailFormItem from 'components/profile/EmailFormItem';
import PhoneFormItem from 'components/profile/PhoneFormItem';
import AddressFormSection from 'components/shared/AddressFormSection';
import { CompanyUserFormValues } from 'store/types/FormValues';
import Address from 'store/types/Address';
import { ParametersContext } from 'components/ParametersGuard';
import TitleFormItem from 'components/profile/TitleFormItem';
import SelectOption from 'store/types/SelectOption';

import styles from './AddCompanyUserModal.module.scss';

interface AddCompanyUserModalProps extends ModalProps {
  companyId: string;
  portalRolesList: SelectOption[];
  refetch: () => void;
  showJobFunctionDropdown: boolean;
  preSelectedJobFunctionId?: string;
  predefinedAddress?: Address;
}

type AddCompanyUserFormValues = CompanyUserFormValues;

const defaultValues: AddCompanyUserFormValues = {
  firstName: '',
  lastName: '',
  middleInitial: '',
  email: '',
  address: getAddressFormValues({ country: 'US' }),
  jobFunctionId: '',
  phone: '',
  title: '',
};

const AddCompanyUserModal: React.FunctionComponent<AddCompanyUserModalProps> = ({
  open,
  onClose,
  companyId,
  portalRolesList,
  refetch,
  showJobFunctionDropdown,
  preSelectedJobFunctionId = '',
  predefinedAddress,
}) => {
  const form = useForm<AddCompanyUserFormValues>({ ...defaultFormProps, defaultValues });
  const {
    companyManagement: { addEmployeeModalHeader, addAdminModalHeader },
  } = useContext(ParametersContext);
  const {
    handleSubmit,
    formState: { isValid },
    control,
    setValue,
    reset,
  } = form;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (predefinedAddress) {
      setValue('address', predefinedAddress);
    }
  }, [predefinedAddress, setValue]);

  useEffect(() => {
    setValue('jobFunctionId', preSelectedJobFunctionId);
  }, [preSelectedJobFunctionId, setValue]);

  const handleAddUserSubmit = useCallback(
    (formValues: AddCompanyUserFormValues) => {
      setLoading(true);

      (showJobFunctionDropdown
        ? CompanyService.addCompanyAdmin(formValues, companyId)
        : CompanyService.addCompanyEmployee(formValues, companyId)
      )
        .then(() => {
          enqueueSnackbar('User successfully added', { variant: 'success' });
          setLoading(false);
          onClose();
          refetch();
          reset(defaultValues);
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setLoading(false);
        });
    },
    [companyId, enqueueSnackbar, onClose, refetch, reset, showJobFunctionDropdown]
  );

  const handleClose = useCallback(() => {
    onClose();
    reset(defaultValues);
  }, [onClose, reset]);

  return (
    <FormProvider {...form}>
      <Modal
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
        title={showJobFunctionDropdown ? 'Create New Contact' : 'Create New Employee'}
        loading={loading}
        actions={
          <>
            <div>
              <Button color={'secondary'} variant={'outlined'} onClick={handleClose}>
                {'Cancel'}
              </Button>
            </div>
            <Button
              color={'secondary'}
              variant={'contained'}
              type={'submit'}
              disabled={!isValid}
              onClick={handleSubmit(handleAddUserSubmit)}
            >
              {'Save'}
            </Button>
          </>
        }
      >
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: showJobFunctionDropdown ? addAdminModalHeader : addEmployeeModalHeader }}
        />
        <h1 className={styles.title}>{'New Account Information'}</h1>
        <Divider className={styles.divider} />
        <Grid {...defaultGridContainerProps}>
          <UserNameFormSection middleNameVisible={true} nicknameVisible={true} />
          <Grid {...defaultGridItemProps} md={6}>
            <EmailFormItem />
          </Grid>
          <Grid {...defaultGridItemProps} md={6}>
            <PhoneFormItem />
          </Grid>
          <Grid {...defaultGridItemProps} md={6}>
            <TitleFormItem />
          </Grid>
          {showJobFunctionDropdown && (
            <Grid {...defaultGridItemProps} md={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...getValidationProps('jobFunctionName')}
                    label={'Portal Role'}
                    required={true}
                    select={true}
                  >
                    {portalRolesList.map(({ id, name }: SelectOption) => (
                      <MenuItem value={id} key={`portal-role-${id}`}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                name={'jobFunctionId'}
                control={control}
                rules={{ required: getRequiredValidationRule('portal role', true) }}
              />
            </Grid>
          )}
          <AddressFormSection />
        </Grid>
      </Modal>
    </FormProvider>
  );
};

export default AddCompanyUserModal;
