import React, { useCallback, ChangeEvent } from 'react';
import { Link, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { getValidationProps, FormProps, validateEmailValue } from 'util/Form';
import routes from 'store/configs/Routes';
import { EMAIL_FIELD_NAME } from 'store/configs/FormFieldNames';
import { getHashRouteUrl } from 'util/Route';

import styles from './EmailFormItem.module.scss';

interface EmailFormItemProps extends FormProps {
  disabled?: boolean;
  currentUserEmail?: boolean;
  optional?: boolean;
  fieldName?: string;
  copyDisabled?: boolean;
}

const nonEditableHelperText: React.ReactNode = (
  <span className={styles.helperText}>
    {'Your email address is used as account login. To edit, please to go '}
    <Link href={getHashRouteUrl(routes.settingsAccount)} underline={'always'} color={'inherit'}>
      {'Account Settings'}
    </Link>
    .
  </span>
);

const EmailFormItem: React.FunctionComponent<EmailFormItemProps> = ({
  disabled = false,
  currentUserEmail = false,
  optional = false,
  fieldName = EMAIL_FIELD_NAME,
  copyDisabled = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const validationProps = currentUserEmail
    ? { helperText: nonEditableHelperText }
    : !disabled
    ? getValidationProps(fieldName, errors)
    : {};

  const validateField = useCallback(
    (value: string) => validateEmailValue(value, disabled, optional),
    [disabled, optional]
  );

  const handleChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value.replace(/\s/g, '')),
    []
  );

  const handleCopy = useCallback(
    (e) => {
      if (copyDisabled) {
        e.preventDefault();
      }
    },
    [copyDisabled]
  );

  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <TextField
          {...validationProps}
          label={'Email Address'}
          value={value}
          onChange={handleChange(onChange)}
          required={!optional}
          disabled={currentUserEmail || disabled}
          autoComplete={'off'}
          onCopy={handleCopy}
        />
      )}
      name={fieldName}
      control={control}
      rules={{ validate: validateField }}
    />
  );
};
export default EmailFormItem;
