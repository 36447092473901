import { Button, Grid, IconButton, IconButtonProps, Tooltip } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import classNames from 'classnames';
import StatusLabel from 'components/shared/StatusLabel';
import Table from 'components/shared/Table';
import { Moment } from 'moment-timezone';
import React, { ChangeEvent, Reducer, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import EventStatus from 'store/enums/events/EventStatus';
import { EventSessionView } from 'store/types/events/Event';
import { SortConfig, TableColumn } from 'store/types/Table';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getPrice } from 'util/Payment';
import SearchInput from 'components/shared/SearchInput';
import EventSessionsCategoryFilter from './EventSessionsCategoryFilter';
import EventSessionsDateFilter from './EventSessionsDateFilter';
import reducer, {
  EventSessionsTableAction,
  EventSessionsTableActionType,
  EventSessionsTableState,
  initialState,
} from './EventSessionsTableReducer';
import { getFormattedEventDate, getFormattedEventTime } from 'util/Event';
import { UserContext } from 'components/UserGuard';
import { EMPTY_STRING_VALUE } from 'util/Format';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import eventStatusConfig from 'store/configs/EventStatusConfig';
import AddToWaitListModal from 'components/events/AddToWaitListModal';

import styles from './EventSessionsTable.module.scss';

interface EventSessionsTableProps {
  data: EventSessionView[];
  loading?: boolean;
  onChange: (selectedSessions: EventSessionView[]) => void;
  isGuestSelection?: boolean;
}

const actionButtonProps: Partial<IconButtonProps> = {
  size: 'small',
  classes: {
    disabled: styles.actionButtonDisabled,
  },
};

const getStatusLabel = (status: EventStatus): React.ReactNode => {
  const { theme = 'grey', name = '' } = eventStatusConfig[status];

  return <StatusLabel theme={theme} status={name} />;
};

const isSessionAvailable = (session: EventSessionView, userMemberType = ''): boolean =>
  !session.memberTypes?.length || !!session.memberTypes?.find(({ name }) => name === userMemberType);

const EventSessionsTable: React.FunctionComponent<EventSessionsTableProps> = ({
  onChange,
  data = [],
  loading = false,
  isGuestSelection,
}) => {
  const [{ sort, filter, list = [], initialList = [], selectedItem }, dispatch] = useReducer<
    Reducer<EventSessionsTableState, EventSessionsTableAction>
  >(reducer, initialState);
  const { memberTypeName } = useContext(UserContext);

  const clearButtonDisabled: boolean = useMemo(
    () => filter && !((filter.tracks || []).length || filter.search || filter.startDate || filter.endDate),
    [filter]
  );
  const selectedSessions = useMemo(
    () => initialList.filter(({ status }) => status === EventStatus.Selected),
    [initialList]
  );

  useEffect(() => {
    const list: EventSessionView[] = data.map((session) => ({
      ...session,
      status: isGuestSelection && session.status === EventStatus.Waitlist ? EventStatus.Full : session.status,
    }));

    if (data.length) {
      dispatch({
        type: EventSessionsTableActionType.SetInitialList,
        payload: { initialList: list },
      });
    }
  }, [data, isGuestSelection]);

  useEffect(() => {
    onChange(selectedSessions);
  }, [selectedSessions, onChange]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: EventSessionsTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const handleAddButtonClick = useCallback(
    (selectedId: EventSessionView['id']) => () => {
      dispatch({ type: EventSessionsTableActionType.AddItem, payload: { selectedId } });
    },
    []
  );

  const handleRemoveButtonClick = useCallback(
    (selectedId: EventSessionView['id']) => () => {
      dispatch({ type: EventSessionsTableActionType.RemoveItem, payload: { selectedId } });
    },
    []
  );

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: EventSessionsTableActionType.UpdateFilter,
      payload: { filter: { search: e.target.value } },
    });
  }, []);

  const handleCategoryFilterChange = useCallback((tracks: string[] = []) => {
    dispatch({
      type: EventSessionsTableActionType.UpdateFilter,
      payload: { filter: { tracks } },
    });
  }, []);

  const handleClearButtonClick = useCallback(() => {
    dispatch({
      type: EventSessionsTableActionType.ResetFilter,
      payload: {},
    });
  }, []);

  const handleDateFilterChange = useCallback((startDate: Moment | null, endDate: Moment | null) => {
    dispatch({
      type: EventSessionsTableActionType.UpdateFilter,
      payload: { filter: { startDate, endDate } },
    });
  }, []);

  const handleAddWaitListModalOpen = useCallback(
    (session: EventSessionView) => () => {
      dispatch({
        type: EventSessionsTableActionType.OpenAddWaitListModal,
        payload: { selectedItem: session },
      });
    },
    []
  );

  const handleAddWaitListModalClose = useCallback(() => {
    dispatch({
      type: EventSessionsTableActionType.CloseAddWaitListModal,
      payload: {},
    });
  }, []);

  const columns: Array<TableColumn<EventSessionView>> = [
    {
      dataIndex: 'status',
      label: 'Status',
      align: 'center',
      render: (_: any, session: EventSessionView) =>
        isSessionAvailable(session, memberTypeName)
          ? session.status && getStatusLabel(session.status)
          : getStatusLabel(EventStatus.NotAvailable),
    },
    {
      dataIndex: 'title',
      label: 'Name',
      sortable: true,
      render: (_: any, { title, summaryDescription = '' }: EventSessionView) =>
        summaryDescription ? (
          <Tooltip
            arrow={true}
            componentsProps={{ tooltip: { className: styles.tooltipContent } }}
            placement={'top'}
            title={summaryDescription}
          >
            <span className={styles.tooltip}>
              <InfoOutlined color={'info'} className={styles.icon} />
              {title}
            </span>
          </Tooltip>
        ) : (
          <span>{title}</span>
        ),
    },
    {
      dataIndex: 'track',
      label: 'Track',
      sortable: true,
    },
    {
      dataIndex: 'startDate',
      label: 'Date',
      sortable: true,
      render: (_: any, { startDate, endDate }: EventSessionView) => getFormattedEventDate(startDate, endDate),
    },
    {
      label: 'Time',
      align: 'center',
      render: (_: any, { startDate, endDate, timeZoneName }: EventSessionView) =>
        endDate && startDate.isSame(endDate, 'days')
          ? getFormattedEventTime(startDate, endDate, timeZoneName)
          : EMPTY_STRING_VALUE,
    },
    {
      dataIndex: 'price',
      label: 'Price',
      sortable: true,
      render: (price: number) => getPrice(price),
    },
    {
      key: 'action',
      label: 'Action',
      align: 'center',
      render: (_: any, session: EventSessionView) =>
        !isSessionAvailable(session, memberTypeName) ? (
          <Tooltip arrow={true} title={'Product is currently not available for your member type'}>
            <div>
              <IconButton
                {...actionButtonProps}
                className={classNames(styles.actionButton, styles.removeButton)}
                disabled={true}
              >
                <Add />
              </IconButton>
            </div>
          </Tooltip>
        ) : session.status === EventStatus.Available ? (
          <IconButton
            {...actionButtonProps}
            className={classNames(styles.actionButton, styles.addButton)}
            onClick={handleAddButtonClick(session.id)}
          >
            <Add />
          </IconButton>
        ) : session.status === EventStatus.NotAvailable || session.status === EventStatus.Full ? (
          <div>
            <IconButton
              {...actionButtonProps}
              className={classNames(styles.actionButton, styles.removeButton)}
              disabled={true}
            >
              <Remove />
            </IconButton>
          </div>
        ) : session.status === EventStatus.Waitlist ? (
          <Button variant={'outlined'} color={'secondary'} onClick={handleAddWaitListModalOpen(session)}>
            {'Join Waitlist'}
          </Button>
        ) : (
          <IconButton
            {...actionButtonProps}
            className={classNames(styles.actionButton, styles.removeButton)}
            onClick={handleRemoveButtonClick(session.id)}
          >
            <Remove />
          </IconButton>
        ),
    },
  ];

  return (
    <>
      <Grid {...defaultGridContainerProps} alignItems={'flex-end'}>
        <Grid {...defaultGridItemProps} md={4} lg={3}>
          <EventSessionsCategoryFilter value={filter.tracks} onChange={handleCategoryFilterChange} />
        </Grid>
        <Grid {...defaultGridItemProps} md={8} lg={5}>
          <EventSessionsDateFilter
            startDate={filter.startDate}
            endDate={filter.endDate}
            onChange={handleDateFilterChange}
          />
        </Grid>
        <Grid {...defaultGridItemProps} lg={4} className={styles.searchInputWrapper}>
          <SearchInput
            value={filter.search}
            onChange={handleSearchChange}
            loading={loading}
            placeholder={'Search by Session Name'}
          />
        </Grid>
        <Grid {...defaultGridItemProps} sm={'auto'} className={styles.clearButtonWrapper}>
          <Button
            color={'primary'}
            variant={'text'}
            className={styles.clearButton}
            disabled={clearButtonDisabled}
            onClick={handleClearButtonClick}
          >
            {'Clear Filters'}
          </Button>
        </Grid>
        <Grid {...defaultGridItemProps}>
          <Table columns={columns} list={list} sort={sort} onSortChange={handleSortChange} loading={loading} />
        </Grid>
      </Grid>
      {selectedItem && (
        <AddToWaitListModal data={selectedItem} open={!!selectedItem} onClose={handleAddWaitListModalClose} />
      )}
    </>
  );
};
export default EventSessionsTable;
