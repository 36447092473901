import Alert from '@mui/material/Alert';
import React, { Reducer, SyntheticEvent, useCallback, useEffect, useReducer, useState } from 'react';
import Table from 'components/shared/Table';
import { EMPTY_STRING_VALUE, getEmailLink, getFullDate, getUserFullName } from 'util/Format';
import { FilterConfig, SortConfig, TableColumn } from 'store/types/Table';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import UseRequestData from 'store/types/UseRequestData';
import classNames from 'classnames';
import { Grid, Button, Radio } from '@mui/material';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import reducer, {
  initialState,
  TableRelationshipUser,
  UserRelationshipTableAction,
  UserRelationshipTableActionType,
  UserRelationshipTableState,
} from './UserRelationshipTableReducer';
import UserRelationshipService from 'services/api/UserRelationshipService';
import { UserRelationship } from 'store/types/UserRelationship';
import SelectOption from 'store/types/SelectOption';

import styles from './UserRelationshipTable.module.scss';
import commonStyles from 'styles/common.module.scss';

interface UserRelationshipTableProps extends UseRequestData<UserRelationship[]> {
  types?: SelectOption[];
  filter: FilterConfig<UserRelationship>;
  onSelectUserId?: (userId: string) => void;
  showRadioButtons?: boolean;
  customerId?: string;
}

const UserRelationshipTable: React.FunctionComponent<UserRelationshipTableProps> = ({
  data,
  loading,
  error,
  refetch,
  filter,
  types,
  onSelectUserId,
  showRadioButtons,
  customerId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [{ sort, list = [], selectedItem }, dispatch] = useReducer<
    Reducer<UserRelationshipTableState, UserRelationshipTableAction>
  >(reducer, initialState);

  useEffect(() => {
    if (data) {
      const initialList: TableRelationshipUser[] = data.map((user) => ({
        ...user,
        userFullName: getUserFullName(user, true) || EMPTY_STRING_VALUE,
        typeName: types?.find(({ id }) => id === user.typeId)?.name || EMPTY_STRING_VALUE,
      }));

      dispatch({
        type: UserRelationshipTableActionType.SetInitialList,
        payload: { initialList: initialList },
      });
    }
  }, [data, types]);

  useEffect(() => {
    dispatch({
      type: UserRelationshipTableActionType.UpdateFilter,
      payload: { filter },
    });
  }, [filter]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({
      type: UserRelationshipTableActionType.UpdateSort,
      payload: { sort: newSort },
    });
  }, []);

  const handleDeleteButtonClick = useCallback(
    (record) => () => {
      dispatch({
        type: UserRelationshipTableActionType.OpenDeleteModal,
        payload: { selectedItem: record },
      });
    },
    []
  );

  const handleDeleteModalClose = useCallback(() => {
    dispatch({
      type: UserRelationshipTableActionType.CloseDeleteModal,
      payload: {},
    });
  }, []);

  const handleDeleteUserSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (selectedItem) {
        setLoadingModal(true);
        UserRelationshipService.deleteRelationshipUser(selectedItem.id, customerId)
          .then(() => {
            enqueueSnackbar('User successfully deleted', { variant: 'success' });
            dispatch({
              type: UserRelationshipTableActionType.CloseDeleteModal,
              payload: {},
            });
            setLoadingModal(false);
            refetch();
          })
          .catch((errorMessage) => {
            setLoadingModal(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      }
    },
    [customerId, enqueueSnackbar, refetch, selectedItem]
  );

  const handleRadioButtonClick = useCallback(
    (selectedItem: TableRelationshipUser) => () => {
      if (onSelectUserId) {
        setSelectedUserId(selectedItem.relatedCustomerId);
        onSelectUserId(String(selectedItem.relatedCustomerId));
      }
    },
    [onSelectUserId]
  );

  const columns: Array<TableColumn<TableRelationshipUser>> = [
    {
      key: 'checkbox',
      label: '',
      align: 'center',
      hidden: !showRadioButtons,
      render: (_: any, record) => (
        <Radio
          disabled={record.isUserRegistered}
          color={'primary'}
          checked={selectedUserId === record.relatedCustomerId}
          onChange={handleRadioButtonClick(record)}
        />
      ),
    },
    {
      dataIndex: 'entityId',
      label: 'Customer ID',
      align: 'center',
      sortable: true,
    },
    {
      dataIndex: 'userFullName',
      label: 'Name',
      sortable: true,
    },
    {
      dataIndex: 'email',
      label: 'Email',
      sortable: true,
      render: (email: string) => getEmailLink(email),
    },
    {
      dataIndex: 'typeName',
      label: 'Type',
      sortable: true,
    },
    {
      dataIndex: 'startDate',
      label: 'Creation Date',
      sortable: true,
      align: 'center',
      render: (startDate) => getFullDate(startDate),
    },
    {
      label: 'Action',
      align: 'center',
      render: (_: any, record: TableRelationshipUser) => (
        <Button
          size={'small'}
          onClick={handleDeleteButtonClick(record)}
          variant={'outlined'}
          className={classNames(commonStyles.dangerButtonOutlined, styles.deleteButton)}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'center'}>
        {error ? (
          <Grid {...defaultGridItemProps}>
            <Alert severity={'error'} className={commonStyles.alert}>
              {error}
            </Alert>
          </Grid>
        ) : (
          <>
            <Grid {...defaultGridItemProps}>
              <Table
                columns={columns}
                list={list}
                sort={sort}
                onSortChange={handleSortChange}
                loading={loading}
                showPagination={true}
              />
            </Grid>
          </>
        )}
        <Modal
          title={'Delete Relationship With The User'}
          loading={loadingModal}
          open={!!selectedItem}
          maxWidth={'sm'}
          actions={
            <>
              <Button color={'secondary'} variant={'outlined'} onClick={handleDeleteModalClose}>
                {'Cancel'}
              </Button>
              <Button
                type={'submit'}
                variant={'contained'}
                onClick={handleDeleteUserSubmit}
                className={commonStyles.dangerButtonContained}
              >
                {'Delete'}
              </Button>
            </>
          }
        >
          <p className={commonStyles.text}>{'Are you sure you want to delete this User?'}</p>
        </Modal>
      </Grid>
    </>
  );
};
export default UserRelationshipTable;
