import { getAddressFormValues } from 'util/Form';
import CommunityMember from 'store/types/CommunityMember';
import { UserFormValues } from 'store/types/FormValues';
import JobFunction, { UserJobFunction } from 'store/types/JobFunction';
import UserPermissions from 'store/types/UserPermissions';
import CompanyAccessRequestStatus from '../store/enums/CompanyAccessRequestStatus';
import React from 'react';
import companyAccessRequestStatusConfig from 'store/configs/CompanyAccessRequestStatusConfig';
import StatusLabel from 'components/shared/StatusLabel';
import SiteSection from 'store/enums/SiteSection';
import SelectOption from 'store/types/SelectOption';

export const emptyUserFormValues: UserFormValues = {
  firstName: '',
  lastName: '',
  middleInitial: '',
  phone: '',
  email: '',
  address: getAddressFormValues(),
  birthDate: '',
};

export const getAvailablePortalRoles = (functions: JobFunction[], userRoles: string[]): SelectOption[] => {
  const availablePortalRoles: SelectOption[] = [];
  userRoles.forEach((role) =>
    functions
      .find(({ name }) => name === role)
      ?.availableRoles.forEach(
        (role: SelectOption) =>
          !!availablePortalRoles.find(({ id }) => id === role.id) || availablePortalRoles.push(role)
      )
  );

  return availablePortalRoles;
};

export const hasCommitteeChairRole = ({ role }: CommunityMember): boolean => role.toLowerCase() === 'chair';

export const hasChapterAdminRole = ({ role }: CommunityMember): boolean => role.toLowerCase() === 'chair';

export const getUserRoles = (functions: UserJobFunction[] = []): string[] =>
  Array.from(new Set(functions?.map(({ jobFunctionNames }) => jobFunctionNames).flat()));

export const isModuleAvailable = (
  siteModule: string,
  functions: UserJobFunction[] = [],
  userPermissions: UserPermissions
): boolean => {
  const userRoles: string[] = getUserRoles(functions);
  const foundPermissions = userPermissions.modules.find(({ name }) => name === siteModule);

  if (foundPermissions) {
    let isModuleAvailable = false;
    foundPermissions.permissions.filter((permissionRole) => {
      if (userRoles.includes(permissionRole)) {
        isModuleAvailable = true;
      }
    });
    return isModuleAvailable;
  } else {
    return true;
  }
};

export const isSectionAvailable = (
  siteSection: SiteSection,
  memberType = '',
  userPermissions: UserPermissions
): boolean => {
  const foundPermissions = userPermissions.sections.find(({ name }) => name === siteSection);

  if (foundPermissions) {
    let isSectionAvailable = false;
    if (foundPermissions.permissions.find((permissionRole) => permissionRole === memberType)) {
      isSectionAvailable = true;
    }
    return isSectionAvailable;
  } else {
    return true;
  }
};

export const getEmployerRequestStatusLabel = (status: CompanyAccessRequestStatus): React.ReactNode => {
  const { theme = 'grey', name = '' } = companyAccessRequestStatusConfig[status];

  return <StatusLabel theme={theme} status={name} />;
};
