import React, { ChangeEvent, useCallback } from 'react';
import { FormControl, FormGroup, Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormProps, getValidationProps } from 'util/Form';
import { ApplicationQuestionView } from 'store/types/ApplicationQuestion';
import { getCheckboxFieldName } from 'util/Application';

interface CheckboxQuestionFormItemProps extends FormProps {
  question: ApplicationQuestionView;
}

interface CheckboxFormValue {
  id: string;
  name: string;
}

const CheckboxQuestionFormItem: React.FunctionComponent<CheckboxQuestionFormItemProps> = ({ question, disabled }) => {
  const { required, fieldName } = question;
  const {
    control,
    formState: { errors },
  } = useFormContext<{ [fieldName: string]: CheckboxFormValue[] }>();
  const { fields } = useFieldArray({ control, name: fieldName });
  const resultFieldName = getCheckboxFieldName(fieldName);
  const { error = false, helperText = '' } = getValidationProps(resultFieldName, errors);

  const handleChange = useCallback((onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked), []);

  return (
    <>
      <FormControl component={'fieldset'} required={required} error={error} disabled={disabled}>
        <FormGroup>
          {fields.map(({ id, name = '' }, index) => (
            <FormControlLabel
              key={id}
              label={name}
              control={
                <>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        color={'primary'}
                        size={'small'}
                        checked={value}
                        value={value}
                        onChange={handleChange(onChange)}
                      />
                    )}
                    control={control}
                    name={`${fieldName}[${index}].value`}
                  />
                </>
              }
            />
          ))}
        </FormGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};
export default CheckboxQuestionFormItem;
