import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { PreferenceQuestionAnswerFormValues, PreferenceQuestionView } from 'store/types/PreferenceQuestion';
import { getDefaultPreferencesFormValues, getPreferencesAnswersData, PREFERENCES_FIELD_NAME } from 'util/Preferences';
import CommunicationsPreferences, { CommunicationsPreferencesData } from 'store/types/CommunicationsPreferences';
import { ParametersContext } from 'components/ParametersGuard';
import Card from 'components/shared/Card';
import EditPreferencesForm from 'components/profile/EditPreferencesPage/EditPreferencesForm';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { defaultFormProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import Spinner from 'components/shared/Spinner';
import FormFooter from 'components/shared/FormFooter';
import PreferencesService from 'services/api/PreferencesService';
import { useSnackbar } from 'notistack';
import { ConfigContext } from 'components/ConfigGuard';

import commonStyles from 'styles/common.module.scss';

interface CommunicationsPreferencesPageViewProps {
  data: CommunicationsPreferences;
  questions: PreferenceQuestionView[];
  id: string;
}

interface CommunicationsPreferencesFormValues extends Pick<CommunicationsPreferences, 'isSubscribed'> {
  preferences: PreferenceQuestionAnswerFormValues;
}

const getDefaultValues = (
  data: CommunicationsPreferences,
  questions: PreferenceQuestionView[]
): CommunicationsPreferencesFormValues => ({
  preferences: getDefaultPreferencesFormValues(questions),
  isSubscribed: data.isSubscribed,
});

const CommunicationsPreferencesPageView: React.FunctionComponent<CommunicationsPreferencesPageViewProps> = ({
  data,
  id,
  questions,
}) => {
  const {
    profile: { communicationsPreferencesHeader },
  } = useContext(ParametersContext);
  const { isSmacnaTheme } = useContext(ConfigContext);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<CommunicationsPreferencesFormValues>({
    ...defaultFormProps,
    defaultValues: getDefaultValues(data, questions),
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = form;
  const submitButtonDisabled: boolean = useMemo(() => !isValid, [isValid]);
  const preferences = useWatch({ control, name: 'preferences' });

  useEffect(() => {
    if (preferences) {
      const isTouched: boolean = getPreferencesAnswersData(preferences, questions).some(
        ({ answer, selections }) => answer || selections?.length
      );

      if (isTouched) {
        setValue('isSubscribed', false);
      }
    }
  }, [preferences, questions, setValue]);

  const handleChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
      const value: boolean = e.target.checked;

      onChange(value);
      if (value) {
        reset({ isSubscribed: value, preferences: getDefaultPreferencesFormValues(questions, false) });
      }
    },
    [questions, reset]
  );

  const handleFormSubmit = useCallback(
    ({ isSubscribed, preferences }: CommunicationsPreferencesFormValues) => {
      const data: CommunicationsPreferencesData = {
        isSubscribed,
        preferences: getPreferencesAnswersData(preferences, questions),
      };
      setSubmitLoading(true);
      PreferencesService.updateCommunicationsPreferences(id, data)
        .then(() => {
          setSubmitLoading(false);
          enqueueSnackbar(`Communications Preferences successfully updated`, { variant: 'success' });
        })
        .catch((error: string) => {
          enqueueSnackbar(error, defaultSnackbarErrorProps);
          setSubmitLoading(false);
        });
    },
    [enqueueSnackbar, id, questions]
  );

  return (
    <Spinner fullPage={true} loading={submitLoading}>
      <h1 className={commonStyles.pageTitle}>{'Communications Preferences'}</h1>
      {communicationsPreferencesHeader && <p dangerouslySetInnerHTML={{ __html: communicationsPreferencesHeader }} />}
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps} sm={'auto'}>
          <Card bordered={true}>
            <h3>{data?.email}</h3>
          </Card>
        </Grid>
        <FormProvider {...form}>
          <Grid {...defaultGridItemProps}>
            {!!questions.length && <EditPreferencesForm data={questions} parentFieldName={PREFERENCES_FIELD_NAME} />}
          </Grid>
          <Grid {...defaultGridItemProps} display={'flex'} justifyContent={'center'}>
            <FormControlLabel
              control={
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                  )}
                  control={control}
                  name={'isSubscribed'}
                />
              }
              label={
                <i>
                  {isSmacnaTheme
                    ? 'I no longer want to receive any publication emails from SMACNA'
                    : 'I no longer with to be contacted at this email address'}
                </i>
              }
            />
          </Grid>
          <FormFooter onSubmit={handleSubmit(handleFormSubmit)} submitButtonDisabled={submitButtonDisabled} />
        </FormProvider>
      </Grid>
    </Spinner>
  );
};

export default CommunicationsPreferencesPageView;
