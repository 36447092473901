import { Button, Divider, Grid } from '@mui/material';
import classNames from 'classnames';
import CompanySelect from 'components/company/CompanySelect';
import CompanyEmployeesTable from 'components/company/CompanyUsersSection/CompanyEmployeesTable';
import { EventCartContext } from 'components/EventCartGuard';
import { ParametersContext } from 'components/ParametersGuard';
import Modal from 'components/shared/Modal';
import Stepper from 'components/shared/Stepper';
import { UserContext } from 'components/UserGuard';
import useRequest from 'hooks/useRequest';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import CompanyService from 'services/api/CompanyService';
import EventService from 'services/api/EventService';
import SiteModule from 'store/enums/SiteModule';
import CompanyUser from 'store/types/CompanyUser';
import { ModalProps } from 'store/types/ComponentProps';
import { MainEventView } from 'store/types/events/Event';
import EventsCartItem from 'store/types/events/EventsCartItem';
import { CompanySelectOption, getCompanySelectOptions } from 'util/Company';
import { getUserFullName } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import ManageGroupEventSessionsSection from './ManageGroupEventSessionsSection';

import commonStyles from 'styles/common.module.scss';
import styles from './GroupRegistrationModal.module.scss';

interface GroupRegistrationStepConfigItem {
  value: number;
  label: string;
  icon: string;
}

const stepsConfig: GroupRegistrationStepConfigItem[] = [
  { value: 1, label: 'Employees Selection', icon: 'PeopleAlt' },
  { value: 2, label: 'Session Selection', icon: 'LibraryBooks' },
];

interface GroupRegistrationModalProps extends ModalProps {
  event: MainEventView;
}

const GroupRegistrationModal: React.FunctionComponent<GroupRegistrationModalProps> = ({ event, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { functions = [], userLoading, userPermissions } = useContext(UserContext);
  const { refetchCart } = useContext(EventCartContext);
  const {
    events: { groupRegistrationEmployeeSelectionModalHeader, groupRegistrationSessionSelectionModalHeader },
  } = useContext(ParametersContext);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [selectedEmployees, setSelectedEmployees] = useState<CompanyUser[]>([]);
  const [cartItems, setCartItems] = useState<EventsCartItem[]>([]);
  const [step, setStep] = useState<number>(1);
  const currentStepConfigIndex: number = useMemo(() => stepsConfig.findIndex(({ value }) => step === value), [step]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const permissions: string[] = useMemo(
    () => userPermissions.modules.find(({ name }) => name === SiteModule.GroupRegistration)?.permissions || [],
    [userPermissions]
  );
  const options: CompanySelectOption[] = useMemo(
    () => getCompanySelectOptions(functions, permissions),
    [functions, permissions]
  );
  const companyEmployeesRequest = useMemo(
    () =>
      selectedCompanyId ? () => CompanyService.getCompanyEmployeesGroupReg(selectedCompanyId, event.id) : undefined,
    [selectedCompanyId, event.id]
  );
  const companyEmployeesRequestData = useRequest<CompanyUser[]>(companyEmployeesRequest);

  const handleCompanyChange = useCallback((newCompanyId: string) => {
    setSelectedCompanyId(newCompanyId);
    setSelectedEmployees([]);
    setCartItems([]);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    setSubmitLoading(true);
    EventService.addEventsToCart(cartItems)
      .then(() => {
        setSubmitLoading(false);
        enqueueSnackbar('Event was added to cart', {
          variant: 'success',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
        refetchCart();
        handleClose();
      })
      .catch((errorMessage: string) => {
        setSubmitLoading(false);
        enqueueSnackbar(errorMessage, { variant: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } });
      });
  }, [cartItems, enqueueSnackbar, handleClose, refetchCart]);

  const handleSelectEmployees = useCallback(
    (employees: CompanyUser[]) => {
      setSelectedEmployees(employees);

      setCartItems((prevState) => {
        const newCart: EventsCartItem[] = prevState.filter(({ registrantId }) =>
          employees.find(({ id }) => registrantId === id)
        );

        employees.forEach((employee) => {
          if (!newCart.find(({ registrantId }) => registrantId === employee.id)) {
            newCart.push({
              eventId: event.id,
              registrantId: employee.id,
              customerName: getUserFullName(employee, true),
              companyId: selectedCompanyId,
              priceLevelId: employee.priceLevelId,
              memberTypeName: employee.memberTypeName,
            });
          }
        });

        return [...newCart];
      });
    },
    [event, selectedCompanyId]
  );

  const handleNextStep = useCallback(() => {
    const newStepIndex: number = currentStepConfigIndex + 1;
    const nextStep = stepsConfig[newStepIndex].value;

    setStep(nextStep);
  }, [currentStepConfigIndex]);

  const handlePrevStep = useCallback(() => {
    const newStepIndex: number = currentStepConfigIndex - 1;
    const prevStep = stepsConfig[newStepIndex].value;

    setStep(prevStep);
  }, [currentStepConfigIndex]);

  const handleUpdateCart = useCallback((updatedRegistrant: EventsCartItem) => {
    setCartItems((prevState) => {
      const newCart = prevState;
      const index = newCart.findIndex(({ registrantId }) => registrantId === updatedRegistrant.registrantId);

      newCart[index] = updatedRegistrant;
      return [...newCart];
    });
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      maxWidth={'lg'}
      title={'Group Registration'}
      loading={userLoading || submitLoading}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={step === 1 ? handleClose : handlePrevStep}>
            {step === 1 ? 'Close' : 'Back'}
          </Button>
          {step === 1 && !!event.sessions?.length ? (
            <Button
              variant={'contained'}
              color={'secondary'}
              onClick={handleNextStep}
              disabled={!selectedEmployees.length}
            >
              {'Next'}
            </Button>
          ) : (
            <Button variant={'contained'} color={'secondary'} onClick={handleSubmit}>
              {'Submit'}
            </Button>
          )}
        </>
      }
    >
      <Grid {...defaultGridContainerProps}>
        {!!event.sessions?.length && (
          <Grid {...defaultGridItemProps} className={styles.stepper}>
            <Stepper activeStep={step} config={stepsConfig} />
          </Grid>
        )}
        {step === 1 && (
          <Grid {...defaultGridItemProps}>
            <p dangerouslySetInnerHTML={{ __html: groupRegistrationEmployeeSelectionModalHeader }} />
          </Grid>
        )}
        {step === 2 && (
          <Grid {...defaultGridItemProps}>
            <p dangerouslySetInnerHTML={{ __html: groupRegistrationSessionSelectionModalHeader }} />
          </Grid>
        )}
        <Grid {...defaultGridItemProps}>
          <h4 className={commonStyles.subTitle}>{event.title}</h4>
          <Divider className={styles.divider} />
        </Grid>
        <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: step === 2 })}>
          <CompanySelect value={selectedCompanyId} options={options} onChange={handleCompanyChange} size={'small'} />
        </Grid>
        {step === 1 && (
          <>
            <Grid {...defaultGridItemProps}>
              <h4 className={commonStyles.subTitle}>{options.find(({ id }) => id === selectedCompanyId)?.label}</h4>
              <Divider className={styles.divider} />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <CompanyEmployeesTable
                {...companyEmployeesRequestData}
                isSelectTable={true}
                onSelectEmployees={handleSelectEmployees}
                selectedEmployees={selectedEmployees}
              />
            </Grid>
          </>
        )}
        {step === 2 && !!event.sessions && (
          <Grid {...defaultGridItemProps}>
            <ManageGroupEventSessionsSection
              sessions={event.sessions}
              cartItems={cartItems}
              onChange={handleUpdateCart}
            />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};
export default GroupRegistrationModal;
