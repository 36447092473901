import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import PreferenceQuestion, { PreferenceQuestionAnswer, PrivatePreferences } from 'store/types/PreferenceQuestion';
import CommunicationsPreferences, { CommunicationsPreferencesData } from 'store/types/CommunicationsPreferences';

type PreferenceQuestionsResponseData = PreferenceQuestion[];
type PrivatePreferencesResponseData = PrivatePreferences;

class PreferencesService {
  public static getPreferenceQuestions(): Promise<PreferenceQuestionsResponseData> {
    return new Promise<PreferenceQuestionsResponseData>(
      (resolve: (data: PreferenceQuestionsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<PreferenceQuestionsResponseData>(
          HttpService.get(`${API_URL}/preferences`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getPreferenceQuestionsByAlternateId(alternateId: string): Promise<PrivatePreferencesResponseData> {
    return new Promise<PrivatePreferencesResponseData>(
      (resolve: (data: PrivatePreferencesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<PrivatePreferencesResponseData>(
          HttpService.get(`${API_URL}/preferences/${alternateId}`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCommunicationsPreferences(id: string): Promise<CommunicationsPreferences> {
    return new Promise<CommunicationsPreferences>(
      (resolve: (data: CommunicationsPreferences) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CommunicationsPreferences>(
          HttpService.get(`${API_URL}/preferences/communications/${id}`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateCommunicationsPreferences(id: string, data: CommunicationsPreferencesData): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/preferences/communications/${id}`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updatePreferenceQuestions(data: PreferenceQuestionAnswer[]): Promise<PreferenceQuestionsResponseData> {
    return new Promise<PreferenceQuestionsResponseData>(
      (resolve: (value: PreferenceQuestionsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper(
          HttpService.put(`${API_URL}/preferences`, {
            data,
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updatePreferenceQuestionsByAlternateId(
    alternateId: string,
    data: PreferenceQuestionAnswer[]
  ): Promise<PreferenceQuestionsResponseData> {
    return new Promise<PreferenceQuestionsResponseData>(
      (resolve: (value: PreferenceQuestionsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper(
          HttpService.put(`${API_URL}/preferences/${alternateId}`, {
            data,
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}
export default PreferencesService;
