import { Breadcrumbs, Container, Link } from '@mui/material';
import Home from '@mui/icons-material/Home';
import Alert from '@mui/material/Alert';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from 'components/UserGuard';
import { useHistory, useLocation, useParams } from 'react-router';
import EventDetailsPageView from 'components/events/EventDetailsPageView';
import Spinner from 'components/shared/Spinner';
import EventService from 'services/api/EventService';
import { MainEvent, MainEventView } from 'store/types/events/Event';
import { EventsCategories } from 'store/types/events/EventFilter';
import { EMPTY_STRING_VALUE } from 'util/Format';
import useRequest from 'hooks/useRequest';
import NotFoundPage from 'components/layout/NotFoundPage';
import routes from 'store/configs/Routes';
import { getHashRouteUrl } from 'util/Route';
import EventsCartPageView from 'components/events/EventsCartPageView';
import EventCatalogPageView from 'components/events/EventCatalogPageView';
import EventsPageHeader from './EventsPageHeader';
import Sidebar from 'components/layout/Sidebar';
import classNames from 'classnames';
import { useWindowSize } from 'util/Window';
import { getMainEventView } from 'util/Event';

import styles from './EventsCatalogPage.module.scss';
import commonStyles from 'styles/common.module.scss';

const EventsCatalogPage: React.FunctionComponent = () => {
  const { eventId = '' } = useParams<{ eventId: string }>();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const [selectedEvent, setSelectedEvent] = useState<MainEventView>();
  const { userLoading = false, id } = useContext(UserContext);
  const getEventsRequest = useMemo(
    () => (search && !pathname.includes(routes.eventDetails) ? () => EventService.getEvents(search) : undefined),
    [pathname, search]
  );
  const getEventsCategoriesRequest = useMemo(
    () =>
      search && !pathname.includes(routes.eventDetails) ? () => EventService.getEventsCategories(search) : undefined,
    [pathname, search]
  );
  const getEventDetailsRequest = useMemo(
    () => (eventId ? () => EventService.getEventDetails([eventId]) : undefined),
    [eventId]
  );
  const { data, loading, error } = useRequest<MainEvent[]>(getEventsRequest);
  const {
    data: eventDetails,
    loading: eventDetailsLoading,
    error: eventDetailsError,
  } = useRequest<MainEvent[]>(getEventDetailsRequest);
  const {
    data: eventsCategoriesData = [],
    loading: eventsCategoriesLoading,
    error: eventsCategoriesError,
  } = useRequest<EventsCategories[]>(getEventsCategoriesRequest);
  const [events, setEvents] = useState<MainEventView[]>([]);
  const isCartPageView = useMemo(() => pathname === routes.eventCart, [pathname]);
  const isEventDetailsPageView = useMemo(() => !!eventId, [eventId]);
  const hasAuthorizedUser = useMemo(() => !!id, [id]);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (eventDetails?.length && pathname.includes(routes.eventDetails)) {
      history.replace({ pathname, search: '' });
      setSelectedEvent(getMainEventView(eventDetails[0]));
    }
  }, [eventDetails, history, pathname]);

  useEffect(() => {
    if (!eventId) {
      setSelectedEvent(undefined);
    }
  }, [eventId]);

  useEffect(() => {
    setEvents(data?.map((event) => getMainEventView(event)) || []);
  }, [data]);

  return (
    <>
      <Spinner
        loading={
          userLoading ||
          loading ||
          eventsCategoriesLoading ||
          eventDetailsLoading ||
          (!isCartPageView && !isEventDetailsPageView && !search)
        }
        transparent={false}
        fullPage={true}
      >
        <div className={styles.wrapper} id={'main-page-layout'}>
          <div className={classNames(styles.sidebar, { [commonStyles.hidden]: isMobile })}>
            <Sidebar alwaysCollapsed={true} />
          </div>
          <div className={'rootContent'}>
            <EventsPageHeader />
            {(isCartPageView || isEventDetailsPageView) && (
              <Container maxWidth={'lg'} className={styles.breadcrumbsWrapper}>
                <Breadcrumbs separator={'›'} className={styles.breadcrumbs}>
                  <Link color={'inherit'} href={getHashRouteUrl(routes.home)}>
                    <Home className={styles.homeIcon} />
                  </Link>
                  <Link color={'inherit'} href={getHashRouteUrl(routes.eventsCatalog)}>
                    {'Catalog'}
                  </Link>
                  <Link color={'textPrimary'} className={styles.currentLink}>
                    {isCartPageView ? 'Checkout' : selectedEvent?.title || EMPTY_STRING_VALUE}
                  </Link>
                </Breadcrumbs>
              </Container>
            )}
            {isCartPageView ? (
              hasAuthorizedUser ? (
                <EventsCartPageView />
              ) : (
                <Container maxWidth={'lg'}>
                  <NotFoundPage />
                </Container>
              )
            ) : error || eventsCategoriesError || eventDetailsError ? (
              <Alert severity={'error'}>{error || eventsCategoriesError || eventDetailsError}</Alert>
            ) : selectedEvent ? (
              <EventDetailsPageView data={selectedEvent} />
            ) : (
              <EventCatalogPageView data={events} categories={eventsCategoriesData} />
            )}
          </div>
        </div>
      </Spinner>
    </>
  );
};
export default EventsCatalogPage;
