import SiteModule from 'store/enums/SiteModule';
import routes from 'store/configs/Routes';

const moduleRoutesConfig: Partial<Record<SiteModule, string>> = {
  [SiteModule.Addresses]: routes.addresses,
  [SiteModule.Home]: routes.home,
  [SiteModule.Profile]: routes.profile,
  [SiteModule.Committees]: routes.committees,
  [SiteModule.Chapters]: routes.chapters,
  [SiteModule.Membership]: routes.membership,
  [SiteModule.Subscriptions]: routes.subscriptions,
  [SiteModule.PersonalPayments]: routes.personalPayments,
  [SiteModule.DonationsPremium]: routes.manageDonations,
  [SiteModule.GroupPayments]: routes.groupPayments,
  [SiteModule.Settings]: routes.settings,
  [SiteModule.MyEvents]: routes.myEvents,
  [SiteModule.Events]: routes.eventsCatalog,
  [SiteModule.CompanyPayments]: routes.companyPayments,
  [SiteModule.Company]: routes.companyManagement,
  [SiteModule.Applications]: routes.applications,
  [SiteModule.Support]: routes.support,
  [SiteModule.Partner]: routes.partner,
  [SiteModule.MemberDirectory]: routes.memberDirectory,
  [SiteModule.EngagementReport]: routes.engagementReport,
  [SiteModule.PublicCommittees]: routes.publicCommittees,
};

export default moduleRoutesConfig;
