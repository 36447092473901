import { Button, Grid } from '@mui/material';
import Table from 'components/shared/Table';
import TableCountLabel from 'components/shared/TableCountLabel';
import React, { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import routes from 'store/configs/Routes';
import ApplicationStatus from 'store/enums/ApplicationStatus';
import Application from 'store/types/Application';
import { FilterConfig, SortConfig, TableColumn } from 'store/types/Table';
import { getStringValue, NUMBER_DATE_FORMAT } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { getHashRouteUrl } from 'util/Route';
import ApplicationsTableActionButton from 'components/applications/ApplicationsTableActionButton';
import reducer, {
  ApplicationsTableAction,
  ApplicationsTableActionType,
  ApplicationsTableState,
  initialState,
} from './UserApplicationsTableReducer';
import ApplicationsService from 'services/api/ApplicationsService';
import { useSnackbar } from 'notistack';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ApplicationDecisionModal from 'components/applications/ApplicationDecisionModal';
import Modal from 'components/shared/Modal';
import { getApplicationStatusLabel } from 'util/Application';

import commonStyles from 'styles/common.module.scss';
import styles from './UserApplicationsTable.module.scss';

interface UserApplicationsTableProps {
  data?: Application[];
  loading?: boolean;
  filter: FilterConfig<Application>;
  openApplications?: boolean;
  refetch: () => void;
}

const openApplicationStatusList: ApplicationStatus[] = [
  ApplicationStatus.Draft,
  ApplicationStatus.PendingPayment,
  ApplicationStatus.InformationRequired,
];

const getFormattedList = (initialList: Application[], openApplications?: boolean): Application[] => {
  const openApplicationsList: Application[] = [];
  const submittedApplicationsList: Application[] = [];

  initialList.forEach((item) => {
    const newItem = {
      ...item,
      name: item.campaignName ? `${item.name} (${item.campaignName})` : item.name,
    };
    if (openApplicationStatusList.includes(newItem.status as ApplicationStatus)) {
      openApplicationsList.push(newItem);
    } else {
      submittedApplicationsList.push(newItem);
    }
  });
  return openApplications ? openApplicationsList : submittedApplicationsList;
};
const UserApplicationsTable: React.FunctionComponent<UserApplicationsTableProps> = ({
  data = [],
  loading = false,
  filter,
  openApplications = false,
  refetch,
}) => {
  const [deleteLoading, setDeleteLoading] = useState<boolean>();
  const { enqueueSnackbar } = useSnackbar();
  const [
    { sort, list = [], initialList = [], deleteModalOpen = false, decisionModalOpen = false, clickedItem },
    dispatch,
  ] = useReducer<Reducer<ApplicationsTableState, ApplicationsTableAction>>(reducer, initialState);

  useEffect(() => {
    if (data.length) {
      dispatch({
        type: ApplicationsTableActionType.SetInitialList,
        payload: { initialList: getFormattedList(data, openApplications) },
      });
    }
  }, [data, openApplications]);

  useEffect(() => {
    dispatch({
      type: ApplicationsTableActionType.UpdateFilter,
      payload: { filter },
    });
  }, [filter]);

  const handleDeleteModalOpen = useCallback(
    (record: Application) => () => {
      dispatch({
        type: ApplicationsTableActionType.OpenDeleteModal,
        payload: { clickedItem: record },
      });
    },
    []
  );

  const handleDeleteModalClose = useCallback(() => {
    dispatch({
      type: ApplicationsTableActionType.CloseDeleteModal,
      payload: {},
    });
  }, []);

  const handleDecisionModalOpen = useCallback(
    (record: Application) => () => {
      dispatch({
        type: ApplicationsTableActionType.OpenDecisionModal,
        payload: { clickedItem: record },
      });
    },
    []
  );

  const handleDecisionModalClose = useCallback(() => {
    dispatch({
      type: ApplicationsTableActionType.CloseDecisionModal,
      payload: {},
    });
  }, []);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: ApplicationsTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);
  //
  const handleDeleteApplication = useCallback(() => {
    if (clickedItem) {
      setDeleteLoading(true);
      ApplicationsService.deleteApplication(clickedItem.id)
        .then(() => {
          handleDeleteModalClose();
          refetch();
          setDeleteLoading(false);
          enqueueSnackbar('Application successfully deleted', { variant: 'success' });
        })
        .catch((errorMessage: string) => {
          setDeleteLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    }
  }, [clickedItem, enqueueSnackbar, handleDeleteModalClose, refetch]);

  const columns: Array<TableColumn<Application>> = [
    {
      dataIndex: 'status',
      label: 'Status',
      align: 'center',
      sortable: true,
      render: (status: ApplicationStatus) => getApplicationStatusLabel(status),
    },
    {
      dataIndex: 'id',
      label: 'Application #',
      sortable: true,
    },
    {
      dataIndex: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      align: 'center',
      render: (_: any, record: Application) => {
        const { id, status, review, isPayable } = record;

        switch (status) {
          case ApplicationStatus.Approved:
          case ApplicationStatus.Denied:
            return (
              <Grid {...defaultGridContainerProps} spacing={2} justifyContent={'center'}>
                <Grid {...defaultGridItemProps} lg={6} className={styles.actionButtonWrapper}>
                  <ApplicationsTableActionButton
                    actionLabel={'View'}
                    nameLabel={'Decision'}
                    disabled={!review}
                    className={styles.actionButton}
                    onClick={handleDecisionModalOpen(record)}
                  />
                </Grid>
                <Grid {...defaultGridItemProps} lg={6} className={styles.actionButtonWrapper}>
                  <ApplicationsTableActionButton
                    href={getHashRouteUrl(`${routes.viewApplication}/${id}`)}
                    actionLabel={'View'}
                    nameLabel={'Application'}
                  />
                </Grid>
              </Grid>
            );
          case ApplicationStatus.Draft:
            return (
              <Grid {...defaultGridContainerProps} spacing={2} justifyContent={'center'}>
                <Grid {...defaultGridItemProps} lg={6} className={styles.actionButtonWrapper}>
                  <ApplicationsTableActionButton
                    href={getHashRouteUrl(`${routes.editApplication}/${id}`)}
                    actionLabel={'Edit'}
                    nameLabel={'Application'}
                  />
                </Grid>
                <Grid {...defaultGridItemProps} lg={6} className={styles.actionButtonWrapper}>
                  <Button
                    size={'large'}
                    color={'primary'}
                    variant={'outlined'}
                    onClick={handleDeleteModalOpen(record)}
                    className={commonStyles.dangerButtonOutlined}
                  >
                    <DeleteOutlined className={styles.icon} />
                  </Button>
                </Grid>
              </Grid>
            );
          case ApplicationStatus.InformationRequired:
            return (
              <Grid {...defaultGridContainerProps} spacing={2} justifyContent={'center'}>
                <Grid {...defaultGridItemProps} lg={6} className={styles.actionButtonWrapper}>
                  <ApplicationsTableActionButton
                    actionLabel={'View'}
                    nameLabel={'Decision'}
                    disabled={!review}
                    className={styles.actionButton}
                    onClick={handleDecisionModalOpen(record)}
                  />
                </Grid>
                <Grid {...defaultGridItemProps} lg={6} className={styles.actionButtonWrapper}>
                  <ApplicationsTableActionButton
                    href={getHashRouteUrl(`${routes.editApplication}/${id}`)}
                    actionLabel={'Edit'}
                    nameLabel={'Application'}
                    className={styles.actionButton}
                  />
                </Grid>
              </Grid>
            );
          case ApplicationStatus.PendingPayment:
            return (
              isPayable && (
                <ApplicationsTableActionButton
                  href={getHashRouteUrl(`${routes.payApplication}/${id}`)}
                  actionLabel={'Pay'}
                />
              )
            );
          case ApplicationStatus.UnderReview:
          case ApplicationStatus.Submitted:
            return (
              <ApplicationsTableActionButton
                href={getHashRouteUrl(`${routes.viewApplication}/${id}`)}
                actionLabel={'View'}
                nameLabel={'Application'}
              />
            );
        }
      },
    },
  ];
  columns.splice(
    3,
    0,
    openApplications
      ? {
          dataIndex: 'dueDate',
          label: 'Due Date',
          align: 'center',
          sortable: true,
          render: (date?: string) => getStringValue(date, NUMBER_DATE_FORMAT),
        }
      : {
          dataIndex: 'submittedDate',
          label: 'Submitted',
          sortable: true,
          align: 'center',
          render: (date: string) => getStringValue(date, NUMBER_DATE_FORMAT),
        }
  );

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid {...defaultGridItemProps}>
          <TableCountLabel viewCount={list.length} totalCount={initialList.length} loading={loading} />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <Table columns={columns} list={list} sort={sort} onSortChange={handleSortChange} loading={loading} />
        </Grid>
      </Grid>
      <Modal
        title={'Delete Draft Application'}
        open={deleteModalOpen}
        maxWidth={'xs'}
        loading={deleteLoading}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleDeleteModalClose} disabled={deleteLoading}>
              {'Cancel'}
            </Button>
            <Button
              type={'submit'}
              disabled={deleteLoading}
              onClick={handleDeleteApplication}
              variant={'contained'}
              className={commonStyles.dangerButtonContained}
            >
              {'Delete'}
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>{'The draft application will be deleted permanently'}</p>
      </Modal>
      {clickedItem?.review && (
        <ApplicationDecisionModal
          onClose={handleDecisionModalClose}
          open={decisionModalOpen}
          review={clickedItem.review}
          applicationId={clickedItem.id}
          applicationStatus={clickedItem.status}
        />
      )}
    </>
  );
};
export default UserApplicationsTable;
