import { Grid } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import PageCard from 'components/shared/PageCard';
import ComponentProps from 'store/types/ComponentProps';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import CommunityInfoSection from 'components/communities/CommunityInfoSection';
import CommunityFilesSection from 'components/communities/CommunityFilesSection';
import CommunityMembersSection from 'components/communities/CommunityMembersSection';
import CommitteeService from 'services/api/CommitteeService';
import { hasCommitteeChairRole } from 'util/User';
import { JoinedCommunity } from 'store/types/Community';
import useRequest from 'hooks/useRequest';

import styles from './CommitteeDetailsCard.module.scss';

interface CommitteeDetailsCardProps extends ComponentProps {
  data: JoinedCommunity;
}

const fileRequests = {
  uploadFileRequest: CommitteeService.uploadCommitteeFile,
  downloadFileRequest: CommitteeService.getCommitteeFile,
  deleteFileRequest: CommitteeService.deleteCommitteeFile,
};
const membersRequests = {
  getUserRequest: CommitteeService.getCommitteeMember,
  downloadRosterRequest: CommitteeService.getCommitteeMembersRoster,
};
// const eventsRequests = {
//   createEventRequest: CommitteeService.createCommitteeEvent,
//   updateEventRequest: CommitteeService.updateCommitteeEvent,
//   deleteEventRequest: CommitteeService.deleteCommitteeEvent,
//   downloadEventFileRequest: CommitteeService.getCommitteeEventFile,
// };

const CommitteeDetailsCard: React.FunctionComponent<CommitteeDetailsCardProps> = ({ data, className = '' }) => {
  const { member, name, id, roles } = data;
  const detailsRequestFn = useCallback(() => CommitteeService.getCommitteeDetails(id), [id]);
  const detailsRequest = useRequest(detailsRequestFn);
  const { data: detailsData } = detailsRequest;
  const hasChairRole: boolean = useMemo(() => hasCommitteeChairRole(member), [member]);

  return (
    <PageCard title={name} className={className} headerClassName={styles.cardHeader}>
      <Grid {...defaultGridContainerProps} className={styles.container}>
        <Grid {...defaultGridItemProps} md={6}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps}>
              <CommunityInfoSection data={data} />
            </Grid>
            <Grid {...defaultGridItemProps}>
              {/*Hidden due PBI-18379-Hide entire upcoming meetings section from Chapters and Committees*/}
              {/*<CommunityEventsSection*/}
              {/*  {...detailsRequest}*/}
              {/*  data={detailsData ? detailsData.events : undefined}*/}
              {/*  requests={eventsRequests}*/}
              {/*  communityId={id}*/}
              {/*  editEnabled={hasChairRole}*/}
              {/*/>*/}
            </Grid>
          </Grid>
        </Grid>
        <Grid {...defaultGridItemProps} md={6}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps}>
              <CommunityFilesSection
                {...detailsRequest}
                data={detailsData ? detailsData.files : undefined}
                requests={fileRequests}
                communityId={id}
                editEnabled={hasChairRole || member.role.toLowerCase() === 'staff liaison'}
              />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <CommunityMembersSection
                {...detailsRequest}
                data={detailsData ? detailsData.members : undefined}
                communityName={name}
                roles={roles}
                requests={membersRequests}
                communityId={id}
                downloadRosterEnabled={hasChairRole}
                description={'The list of committee members.'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageCard>
  );
};
export default CommitteeDetailsCard;
