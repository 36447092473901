import { Divider, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import Modal from 'components/shared/Modal';
import React, { useCallback, useMemo } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getCountryName, getEmailLink, getPhoneLink, getUserFullName } from 'util/Format';
import { ModalProps } from 'store/types/ComponentProps';
import CommunityMember from 'store/types/CommunityMember';
import Phone from '@mui/icons-material/Phone';
import Domain from '@mui/icons-material/Domain';
import Avatar from 'components/shared/Avatar';
import { PublicUser } from 'store/types/User';
import useRequest from 'hooks/useRequest';
import Address from 'store/types/Address';
import { Email, Language } from '@mui/icons-material';
import Home from '@mui/icons-material/Home';
import { Anchorme } from 'react-anchorme';

import commonStyles from 'styles/common.module.scss';
import styles from './CommunityMemberModal.module.scss';

interface CommunityMemberModalProps extends ModalProps {
  user: CommunityMember;
  communityId: string;
  getUserRequest: (communityId: string, userId: string) => Promise<PublicUser>;
}

const CommunityMemberModal: React.FunctionComponent<CommunityMemberModalProps> = ({
  open,
  user,
  onClose,
  communityId,
  getUserRequest,
}) => {
  const { role, id } = user;
  const userRequest = useCallback(() => getUserRequest(communityId, id), [getUserRequest, communityId, id]);
  const { data, loading, error } = useRequest<PublicUser>(userRequest);
  const email: string = useMemo(() => user.email || data?.email || '', [user, data]);
  const address: Address | undefined = useMemo(() => user.address || data?.address, [user, data]);
  const phone: string = useMemo(() => user.phone || data?.phone || '', [user, data]);
  const companyName: string = useMemo(() => data?.parentOrCustomCompanyName || '', [data]);
  const website: string = useMemo(() => data?.parentCompanyWebsite || '', [data]);

  return (
    <Modal
      disableBackdropClick={false}
      open={open}
      onClose={onClose}
      title={'Member Card'}
      maxWidth={'sm'}
      loading={loading}
    >
      <Grid {...defaultGridContainerProps}>
        {error ? (
          <Grid {...defaultGridItemProps}>
            <Alert severity={'error'} className={commonStyles.alert}>
              {error}
            </Alert>
          </Grid>
        ) : (
          <>
            <Grid {...defaultGridItemProps} sm={5} className={styles.personWrapper}>
              <div className={styles.person}>
                <Avatar src={data ? data.logoUrl : ''} className={styles.avatar} loading={loading} />
                <h4 className={styles.name}>{getUserFullName(user)}</h4>
                <p className={styles.role}>{role}</p>
              </div>
            </Grid>
            <Grid {...defaultGridItemProps} sm={7} className={styles.contactsWrapper}>
              <h5 className={styles.subTitle}>{'Personal Information'}</h5>
              <Divider className={styles.divider} />
              {address && (
                <div className={styles.row}>
                  <Home className={styles.icon} />
                  <div className={styles.locationValue}>
                    <span className={styles.location}>{`${address.city}, ${
                      address.stateRegion ? `${address.stateRegion}` : ''
                    }`}</span>
                    <span className={styles.location}>{getCountryName(address.country)}</span>
                  </div>
                </div>
              )}
              {phone && (
                <div className={styles.row}>
                  <Phone className={styles.icon} />
                  {getPhoneLink(phone)}
                </div>
              )}
              {email && (
                <div className={styles.row}>
                  <Email className={styles.icon} />
                  {getEmailLink(email)}
                </div>
              )}
              {(companyName || website) && (
                <>
                  <h5 className={styles.subTitle}>{'Company Information'}</h5>
                  <Divider className={styles.divider} />
                  {companyName && (
                    <div className={styles.row}>
                      <Domain className={styles.icon} />
                      {companyName}
                    </div>
                  )}
                  {website && (
                    <div className={styles.row}>
                      <Language className={styles.icon} />
                      <Anchorme className={styles.link} target={'_blank'}>
                        {website}
                      </Anchorme>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  );
};
export default CommunityMemberModal;
