import { Divider } from '@mui/material';
import React from 'react';
import Card from 'components/shared/Card';
import { MemberDirectoryUser } from 'store/types/MemberDirectory';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import StatusLabel from 'components/shared/StatusLabel';
import { getAddressInfo, getUserFullName } from 'util/Format';

import styles from './MemberListItem.module.scss';

interface MemberListItemProps {
  data: MemberDirectoryUser;
}

const MemberListItem: React.FunctionComponent<MemberListItemProps> = ({ data }) => {
  const { companyName, address, email, firstName, lastName, middleInitial, suffix, prefix, customerType } = data;
  const { country, city } = address;

  return (
    <Card className={styles.card} contentClassName={styles.content}>
      <div className={styles.title}>
        <span className={styles.name}>
          {firstName ? getUserFullName({ firstName, lastName, middleInitial, suffix, prefix }, true) : companyName}
        </span>
        <StatusLabel status={customerType} />
      </div>
      <div className={styles.location}>
        <LocationOnOutlined />
        <span>{`${city}, ${country}`}</span>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.info}>
        {getAddressInfo({ ...address, label: '' })}
        {address?.phone && <span>{`Phone Number: ${address.phone}`}</span>}
        {email && <span className={styles.email}>{email}</span>}
      </div>
    </Card>
  );
};

export default MemberListItem;
