import Modal from 'components/shared/Modal';
import { Button, Divider, Grid, MenuItem, TextField } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ModalProps } from 'store/types/ComponentProps';
import { AddUserRelationshipData } from 'store/types/UserRelationship';
import UserRelationshipService from 'services/api/UserRelationshipService';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { defaultFormProps, getAddressFormValues, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import UserNameFormSection from 'components/profile/UserNameFormSection';
import EmailFormItem from 'components/profile/EmailFormItem';
import SelectOption from 'store/types/SelectOption';
import AddressFormSection from 'components/shared/AddressFormSection';
import { ParametersContext } from 'components/ParametersGuard';

import styles from './AddNewUserRelationshipModal.module.scss';

interface AddNewUserRelationshipModalProps extends ModalProps {
  types: SelectOption[];
  refetch: () => void;
  customerId?: string;
}

type AddUserRelationshipFormValues = AddUserRelationshipData;

const defaultValues: AddUserRelationshipFormValues = {
  firstName: '',
  lastName: '',
  middleInitial: '',
  typeId: '',
  email: '',
  address: getAddressFormValues({ country: 'US' }),
};

const AddNewUserRelationshipModal: React.FunctionComponent<AddNewUserRelationshipModalProps> = ({
  types,
  open,
  onClose,
  customerId,
  refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    profile: { createNewContactRelationshipsHeader },
  } = useContext(ParametersContext);
  const form = useForm<AddUserRelationshipFormValues>({ ...defaultFormProps, defaultValues });
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = form;

  const handleModalClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleFormSubmit = useCallback(
    (formValues: AddUserRelationshipFormValues) => {
      setLoading(true);
      const data: AddUserRelationshipData = {
        ...formValues,
        customerId,
      };

      UserRelationshipService.addRelationshipUser(data)
        .then(() => {
          enqueueSnackbar('User successfully added', { variant: 'success' });
          setLoading(false);
          handleModalClose();
          refetch();
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setLoading(false);
        });
    },
    [customerId, enqueueSnackbar, handleModalClose, refetch]
  );

  return (
    <FormProvider {...form}>
      <Modal
        loading={loading}
        title={'Create New Contact'}
        open={open}
        maxWidth={'md'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleModalClose}>
              {'Cancel'}
            </Button>
            <Button
              type={'submit'}
              variant={'contained'}
              onClick={handleSubmit(handleFormSubmit)}
              disabled={loading || !isValid}
            >
              {'Save'}
            </Button>
          </>
        }
      >
        <h1 className={styles.title}>{'New Account Information'}</h1>
        <Divider className={styles.divider} />
        <Grid {...defaultGridContainerProps}>
          {createNewContactRelationshipsHeader && (
            <Grid {...defaultGridItemProps}>
              <p
                dangerouslySetInnerHTML={{
                  __html: createNewContactRelationshipsHeader,
                }}
              />
            </Grid>
          )}
          <UserNameFormSection middleNameVisible={true} nicknameVisible={true} />
          <Grid {...defaultGridItemProps} md={6}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  {...getValidationProps('type', errors)}
                  select={true}
                  required={true}
                  label={'Relationship Type'}
                >
                  {types.map(({ name, id }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{ required: getRequiredValidationRule('type', true) }}
              name={'typeId'}
              control={control}
            />
          </Grid>
          <Grid {...defaultGridItemProps} md={6}>
            <EmailFormItem />
          </Grid>
          <AddressFormSection required={false} />
        </Grid>
      </Modal>
    </FormProvider>
  );
};

export default AddNewUserRelationshipModal;
