import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { TextField } from '@mui/material';
import { Controller, FieldErrors, useFormContext, useWatch } from 'react-hook-form';
import {
  countryFieldName,
  DEFAULT_POSTAL_CODE_REGEXP,
  FormProps,
  getAddressControlName,
  getRequiredInputMessage,
  getValidationProps,
  isCanadaSelected,
  isUSSelected,
} from 'util/Form';

interface PostalCodeFormItemProps extends FormProps {
  addressFieldName: string;
}

const US_POSTAL_CODE_REGEXP = /^(?=[0-9\s-]{5,10}$)\d+(\s?\d+)?(-\d+(\s?\d+)?)?$/;
const CA_POSTAL_CODE_REGEXP =
  /^(?=[A-Za-z0-9\s-]{6,7}$)[A-Za-z0-9]+(\s?[A-Za-z0-9]+)*(-[A-Za-z0-9]+(\s?[A-Za-z0-9]+)?)?$/;
const postalCodeFieldName = 'postalCode';

const PostalCodeFormItem: React.FunctionComponent<PostalCodeFormItemProps> = ({
  addressFieldName,
  required = true,
  disabled = false,
}) => {
  const {
    formState: { touchedFields, dirtyFields, errors },
    control,
    trigger,
  } = useFormContext();
  const addressErrors = addressFieldName ? (errors[addressFieldName] as FieldErrors) : errors;
  const touchedFieldValues = addressFieldName ? touchedFields[addressFieldName] : touchedFields;
  const dirtyFieldsValues = addressFieldName ? dirtyFields[addressFieldName] : dirtyFields;
  const postalCodeControlName = useMemo(
    () => getAddressControlName(addressFieldName, postalCodeFieldName),
    [addressFieldName]
  );
  const countryControlName = useMemo(
    () => getAddressControlName(addressFieldName, countryFieldName),
    [addressFieldName]
  );
  const [countryValue, postalCodeValue] = useWatch({ control, name: [countryControlName, postalCodeControlName] });
  const label: string = useMemo(() => (isUSSelected(countryValue) ? 'Zip Code' : 'Postal Code'), [countryValue]);
  const fieldRequired: boolean = useMemo(
    () => required && (isUSSelected(countryValue) || isCanadaSelected(countryValue)),
    [required, countryValue]
  );

  useEffect(() => {
    if (
      countryValue &&
      ((touchedFieldValues && touchedFieldValues[countryFieldName]) ||
        (dirtyFieldsValues && dirtyFieldsValues[countryFieldName]))
    ) {
      trigger(postalCodeControlName);
    }
  }, [
    countryValue,
    trigger,
    postalCodeControlName,
    touchedFieldValues,
    addressFieldName,
    postalCodeValue,
    dirtyFieldsValues,
  ]);

  const validateField = useCallback(
    (value: string) => {
      if (fieldRequired && !value) {
        return getRequiredInputMessage(label.toLowerCase());
      } else {
        if (value) {
          if (isUSSelected(countryValue)) {
            return (
              US_POSTAL_CODE_REGEXP.test(value) ||
              'Please enter valid Zip code. Zip code should contain at least 5 characters and cannot be longer than 10 characters'
            );
          } else if (isCanadaSelected(countryValue)) {
            return (
              CA_POSTAL_CODE_REGEXP.test(value) ||
              'Please enter valid Postal code. Postal code should contain at least 6 characters and cannot be longer than 7 characters'
            );
          } else {
            return (
              DEFAULT_POSTAL_CODE_REGEXP.test(value) ||
              'Postal code should contain only digits and letters and cannot be longer than 20 characters'
            );
          }
        } else {
          return true;
        }
      }
    },
    [countryValue, fieldRequired, label]
  );

  const handleChange = useCallback(
    (onChange, onBlur) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
      onBlur();
    },
    []
  );

  return (
    <Controller
      render={({ field: { onChange, value, onBlur } }) => (
        <TextField
          {...getValidationProps(postalCodeFieldName, addressErrors)}
          label={label}
          value={value}
          onChange={handleChange(onChange, onBlur)}
          disabled={disabled}
          required={fieldRequired}
          inputProps={{ ['data-testid']: `${postalCodeFieldName}-input` }}
          data-testid={`${postalCodeFieldName}-text-field`}
          FormHelperTextProps={{ ['data-testid']: `${postalCodeFieldName}-helper-text` } as any}
        />
      )}
      name={postalCodeControlName}
      control={control}
      rules={{ validate: validateField }}
    />
  );
};
export default PostalCodeFormItem;
