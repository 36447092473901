import { Alert, Button, Container, Grid, GridProps, MenuItem, TextField } from '@mui/material';
import React, { Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getButtonLoadingProps,
} from 'util/Layout';
import SearchInput from 'components/shared/SearchInput';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { defaultFormProps } from 'util/Form';
import { MemberDirectorySearchFilter, MemberDirectoryUser } from 'store/types/MemberDirectory';
import MemberDirectoryService from 'services/api/MemberDirectory';
import MemberDirectorySearchFilterBar from 'components/memberDirectory/MemberDirectorySearchFilterBar';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import reducer, {
  initialState,
  ITEMS_PER_PAGE,
  MemberDirectoryPageAction,
  MemberDirectoryPageActionType,
  MemberDirectoryPageState,
} from './MemberDirectoryPageReducer';
import { getPagesCount } from 'util/Table';
import Pagination from '@mui/material/Pagination';
import MemberListItem from './MemberListItem';
import { useSnackbar } from 'notistack';
import MemberDirectoryCustomerType from 'store/enums/MemberDirectoryCustomerType';
import useRequest from 'hooks/useRequest';
import { ConfigContext } from 'components/ConfigGuard';

import styles from './MemberDirectoryPageView.module.scss';
import commonStyles from 'styles/common.module.scss';

const listItemRowProps: GridProps = { xl: 3, sm: 6, md: 4, xs: true };

interface MemberDirectoryPageViewFormValues {
  keyword: string;
  cityOrZip: string;
  type: MemberDirectoryCustomerType;
  filters: Array<string>;
}

const MemberDirectoryPageView: React.FunctionComponent = () => {
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const form = useForm<MemberDirectoryPageViewFormValues>({
    ...defaultFormProps,
  });
  const {
    modulesSettings: {
      memberDirectory: { pageTitle, pageSubTitle },
    },
  } = useContext(ConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, watch, resetField } = form;
  const [{ pageList = [], page = 1, initialList, filterBarOpen }, dispatch] = useReducer<
    Reducer<MemberDirectoryPageState, MemberDirectoryPageAction>
  >(reducer, initialState);
  const pagesCount: number = useMemo(() => getPagesCount(initialList.length, ITEMS_PER_PAGE), [initialList.length]);
  const { type, cityOrZip, keyword } = watch();
  const searchFiltersRequest = useMemo(() => () => MemberDirectoryService.getSearchFilters(type), [type]);
  const { data, loading, error } = useRequest<MemberDirectorySearchFilter[]>(searchFiltersRequest);
  const searchButtonDisabled: boolean = useMemo(
    () => cityOrZip?.length < 2 && keyword?.length < 2,
    [cityOrZip, keyword]
  );

  useEffect(() => {
    if (type) {
      dispatch({
        type: MemberDirectoryPageActionType.CloseFilterBar,
        payload: {},
      });
      resetField('filters');
    }
  }, [resetField, type]);

  const handleSearchSubmit = useCallback(
    (formValues: MemberDirectoryPageViewFormValues) => {
      setSubmitLoading(true);

      const { keyword, cityOrZip, type, filters } = formValues;

      MemberDirectoryService.searchMember(keyword, cityOrZip, type, filters?.join())
        .then((data: MemberDirectoryUser[]) => {
          dispatch({
            type: MemberDirectoryPageActionType.SetInitialList,
            payload: { initialList: data },
          });
          setSubmitLoading(false);
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setSubmitLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  const handleFilterBarOpen = useCallback(() => {
    dispatch({
      type: MemberDirectoryPageActionType.OpenFilterBar,
      payload: {},
    });
  }, []);

  const handleFilterBarClose = useCallback(() => {
    dispatch({
      type: MemberDirectoryPageActionType.CloseFilterBar,
      payload: {},
    });
  }, []);

  const handlePageChange = useCallback((_: any, newPage: number) => {
    window.scrollTo(0, 500);
    dispatch({
      type: MemberDirectoryPageActionType.UpdatePage,
      payload: { page: newPage },
    });
  }, []);

  return (
    <FormProvider {...form}>
      <div className={styles.container}>
        <section className={styles.banner}>
          <Container maxWidth={'lg'} className={styles.bannerContent}>
            <div className={styles.bannerTitle}>
              <h1>{pageTitle}</h1>
              <div className={styles.subTitleBox}>
                <span>{pageSubTitle}</span>
              </div>
            </div>
            <Grid {...defaultGridContainerProps} className={styles.searchContainer} spacing={2}>
              <Grid {...defaultGridItemProps} md={4}>
                <span className={styles.title}>{'Search By'}</span>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <SearchInput
                      value={value}
                      inputClassName={styles.input}
                      onChange={onChange}
                      placeholder={
                        type === MemberDirectoryCustomerType.Company ? 'Company Name or Keyword' : 'Name or Keyword'
                      }
                    />
                  )}
                  defaultValue={''}
                  name={'keyword'}
                  control={control}
                />
              </Grid>
              <Grid {...defaultGridItemProps} md={2}>
                <span className={styles.title}>{'Type'}</span>
                <Controller
                  render={({ field }) => (
                    <TextField {...field} select={true} className={styles.input} size={'small'}>
                      <MenuItem key={'individual'} value={MemberDirectoryCustomerType.Individual}>
                        {MemberDirectoryCustomerType.Individual}
                      </MenuItem>
                      <MenuItem key={'company'} value={MemberDirectoryCustomerType.Company}>
                        {MemberDirectoryCustomerType.Company}
                      </MenuItem>
                    </TextField>
                  )}
                  name={'type'}
                  defaultValue={MemberDirectoryCustomerType.Individual}
                  control={control}
                />
              </Grid>
              <Grid {...defaultGridItemProps} md={3}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <SearchInput
                      value={value}
                      inputClassName={styles.input}
                      onChange={onChange}
                      placeholder={'City or Zipcode'}
                    />
                  )}
                  defaultValue={''}
                  name={'cityOrZip'}
                  control={control}
                />
              </Grid>
              <Grid {...defaultGridItemProps} md={'auto'}>
                <Button
                  {...getButtonLoadingProps(submitLoading)}
                  variant={'contained'}
                  color={'primary'}
                  onClick={handleSubmit(handleSearchSubmit)}
                  className={styles.searchButton}
                  disabled={submitLoading || searchButtonDisabled}
                >
                  {'Search'}
                </Button>
              </Grid>
              <Grid {...defaultGridItemProps} md={'auto'}>
                <Button
                  {...getButtonLoadingProps(loading)}
                  variant={'contained'}
                  color={'warning'}
                  disabled={!data?.length || loading}
                  className={styles.filterButton}
                  onClick={filterBarOpen ? handleFilterBarClose : handleFilterBarOpen}
                >
                  {'Filters'}
                  {filterBarOpen ? <CloseIcon /> : <FilterListIcon />}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </section>
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : (
          !!data?.length &&
          filterBarOpen && (
            <MemberDirectorySearchFilterBar
              data={data}
              onSubmit={handleSubmit(handleSearchSubmit)}
              loading={submitLoading}
              submitButtonDisabled={searchButtonDisabled}
            />
          )
        )}
        <section className={styles.darkSection}>
          <Container maxWidth={'xl'} className={styles.sectionContent}>
            <Grid {...defaultGridContainerProps}>
              {pageList.length ? (
                pageList.map((user: MemberDirectoryUser, index) => (
                  <Grid {...defaultGridItemProps} {...listItemRowProps} key={`member-${user.id}-${index}`}>
                    <MemberListItem data={user} key={user.id} />
                  </Grid>
                ))
              ) : (
                <Grid {...defaultGridItemProps}>
                  <p className={styles.noResultText}>{'No Results Found'}</p>
                </Grid>
              )}
            </Grid>
          </Container>
        </section>
        <section>
          <Container maxWidth={'xl'} className={styles.sectionContent}>
            <div className={styles.paginationWrapper}>
              <Pagination
                showFirstButton={true}
                showLastButton={true}
                count={pagesCount}
                page={page}
                onChange={handlePageChange}
              />
            </div>
          </Container>
        </section>
      </div>
    </FormProvider>
  );
};
export default MemberDirectoryPageView;
