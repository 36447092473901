import { HttpService } from 'services/HttpService';
import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import SelectOption from 'store/types/SelectOption';
import { EventCountry, EventsCategories } from 'store/types/events/EventFilter';
import EventsCartItem from 'store/types/events/EventsCartItem';
import EventFile from 'store/types/events/EventFile';
import PreferenceQuestion, { PreferenceQuestionAnswer, PrivatePreferences } from 'store/types/PreferenceQuestion';
import { MainEvent } from 'store/types/events/Event';

type EventsResponseData = MainEvent[];
type EventSessionCategoriesResponseData = SelectOption[];
type EventTypesResponseData = SelectOption[];
type EventLocationsResponseData = SelectOption[];
type EventsCartResponseData = EventsCartItem[];
type EventsCategoriesData = EventsCategories[];
type EventsDetailsResponseData = MainEvent[];
type EventCountriesResponseData = EventCountry[];
type PreferenceQuestionsResponseData = PreferenceQuestion[];
type PrivatePreferencesResponseData = PrivatePreferences;

class EventService {
  public static getEventSessionCategories(): Promise<EventSessionCategoriesResponseData> {
    return new Promise<EventSessionCategoriesResponseData>(
      (resolve: (data: EventSessionCategoriesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EventSessionCategoriesResponseData>(
          HttpService.get(`${API_URL}/events/sessions/categories`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getEventTypes(): Promise<EventTypesResponseData> {
    return new Promise<EventTypesResponseData>(
      (resolve: (data: EventTypesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EventTypesResponseData>(
          HttpService.get(`${API_URL}/events/types`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getEventCountries(): Promise<EventCountriesResponseData> {
    return new Promise<EventCountriesResponseData>(
      (resolve: (data: EventCountriesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EventCountriesResponseData>(
          HttpService.get(`${API_URL}/events/countries`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getEventLocations(): Promise<EventLocationsResponseData> {
    return new Promise<EventLocationsResponseData>(
      (resolve: (data: EventLocationsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EventLocationsResponseData>(
          HttpService.get(`${API_URL}/events/locations`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getPreferenceQuestionsByAlternateId(alternateId: string): Promise<PrivatePreferencesResponseData> {
    return new Promise<PrivatePreferencesResponseData>(
      (resolve: (data: PrivatePreferencesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<PrivatePreferencesResponseData>(
          HttpService.get(`${API_URL}/events/${alternateId}/preferences`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updatePreferenceQuestionsByAlternateId(
    alternateId: string,
    data: PreferenceQuestionAnswer[]
  ): Promise<PreferenceQuestionsResponseData> {
    return new Promise<PreferenceQuestionsResponseData>(
      (resolve: (value: PreferenceQuestionsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper(
          HttpService.put(`${API_URL}/events/${alternateId}/preferences`, {
            data,
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getEventFile(eventId: string, { id, name }: EventFile): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/events/${eventId}/files/${id}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: name,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getEvents(search?: string): Promise<EventsResponseData> {
    return new Promise<EventsResponseData>(
      (resolve: (data: EventsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EventsResponseData>(
          HttpService.get(`${API_URL}/events${search}`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getEventDetails(eventsId: string[]): Promise<EventsDetailsResponseData> {
    return new Promise<EventsDetailsResponseData>(
      (resolve: (data: EventsDetailsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EventsDetailsResponseData>(
          HttpService.get(`${API_URL}/events/details`, {
            queryParams: { eventsId },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getEventsCategories(search = ''): Promise<EventsCategoriesData> {
    return new Promise<EventsCategoriesData>(
      (resolve: (data: EventsCategoriesData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EventsCategoriesData>(
          HttpService.get(`${API_URL}/events/count/${search}`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getEventsCart(): Promise<EventsCartResponseData> {
    return new Promise<EventsCartResponseData>(
      (resolve: (data: EventsCartResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EventsCartResponseData>(
          HttpService.get(`${API_URL}/events/cart`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject,
          false
        );
      }
    );
  }

  public static addEventToCart(
    eventId: string,
    sessions: string[] = [],
    registrantId?: string,
    guestId?: string,
    companyId?: string
  ): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/events/cart`, {
          data: [{ eventId, sessions, registrantId, companyId, guestId }],
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static addEventsToCart(cartLines: EventsCartItem[]): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/events/cart`, {
          data: cartLines,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static addEventToWaitlist(eventId: string, numberOfRegistrants: number, reason?: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/events/waitlist`, {
          data: { eventId, reason, numberOfRegistrants },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static removeEventFromCart(eventId: string, guestId?: string, registrantId?: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/events/cart`, {
          queryParams: { eventId, guestId, registrantId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static emptyCart(): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/events/cart`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateCartEventSessions(
    eventId: string,
    sessions: string[] = [],
    registrantId?: string,
    guestId?: string,
    companyId?: string
  ): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/events/cart`, {
          data: [{ eventId, sessions, registrantId, companyId, guestId }],
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default EventService;
