import { getListByPage } from 'util/Table';
import { MemberDirectoryUser } from 'store/types/MemberDirectory';

export enum MemberDirectoryPageActionType {
  SetInitialList = 'SetInitialList',
  UpdatePage = 'UpdatePage',
  OpenFilterBar = 'OpenFilterBar',
  CloseFilterBar = 'CloseFilterBar',
}

export interface MemberDirectoryPageState {
  initialList: MemberDirectoryUser[];
  pageList: MemberDirectoryUser[];
  page: number;
  filterBarOpen?: boolean;
}

export interface MemberDirectoryPageAction {
  type: MemberDirectoryPageActionType;
  payload: Partial<MemberDirectoryPageState>;
}

export const ITEMS_PER_PAGE = 12;

export const initialState: MemberDirectoryPageState = {
  initialList: [],
  pageList: [],
  page: 1,
};

const reducer = (
  state: MemberDirectoryPageState,
  { type, payload }: MemberDirectoryPageAction
): MemberDirectoryPageState => {
  if (type === MemberDirectoryPageActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const pageList = getListByPage(initialList, ITEMS_PER_PAGE, 1);

    return { ...state, initialList, pageList };
  }

  if (type === MemberDirectoryPageActionType.UpdatePage) {
    const { page = 1 } = payload;

    return {
      ...state,
      page,
      pageList: getListByPage(state.initialList, ITEMS_PER_PAGE, page),
    };
  }

  if (type === MemberDirectoryPageActionType.OpenFilterBar) {
    return { ...state, filterBarOpen: true };
  }

  if (type === MemberDirectoryPageActionType.CloseFilterBar) {
    return { ...state, filterBarOpen: false };
  }

  return state;
};

export default reducer;
