import { Button, Grid } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import { UserContext } from 'components/UserGuard';
import EditEmailSection from './EditEmailSection';
import EditPasswordSection from './EditPasswordSection';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getButtonLoadingProps,
} from 'util/Layout';
import { tenantConfig } from 'config';
import Environment from 'store/enums/Environment';
import { isInternalIdpType } from 'util/Route';
import { getLocalStorageValue, SUPER_ADMIN_FLAG_LS_LABEL } from 'util/LocalStorage';
import AdminService from 'services/api/AdminService';
import { useSnackbar } from 'notistack';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';
import pageStyles from './EditSettingsPageView.module.scss';

const EditSettingsPageView: React.FunctionComponent = () => {
  const { email = '' } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>();
  const { enqueueSnackbar } = useSnackbar();
  const {
    settings: { accountSettingsTabHeader },
  } = useContext(ParametersContext);

  const isInternalIdp = isInternalIdpType(tenantConfig);
  const { changeEmailUrl = '', changePasswordUrl = '', environment } = tenantConfig;
  const isUserSuperAdmin = !!getLocalStorageValue(SUPER_ADMIN_FLAG_LS_LABEL);

  const handleResetCache = useCallback(() => {
    setLoading(true);
    AdminService.resetCache()
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Tenant cache successfully has been reset', { variant: 'success' });
      })
      .catch((errorMessage: string) => {
        setLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [enqueueSnackbar]);

  return (
    <>
      {isInternalIdp ? (
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps}>
            {accountSettingsTabHeader && <p dangerouslySetInnerHTML={{ __html: accountSettingsTabHeader }} />}
          </Grid>
          {changeEmailUrl ? (
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
                className={pageStyles.button}
                href={changeEmailUrl + encodeURIComponent(email)}
              >
                {'Change Email'}
              </Button>
            </Grid>
          ) : (
            <Grid {...defaultGridItemProps}>
              <EditEmailSection />
            </Grid>
          )}
          {changePasswordUrl ? (
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
                className={pageStyles.button}
                href={changePasswordUrl + encodeURIComponent(email)}
              >
                {'Change Password'}
              </Button>
            </Grid>
          ) : (
            <Grid {...defaultGridItemProps}>
              <EditPasswordSection />
            </Grid>
          )}
          {environment !== Environment.Production && isUserSuperAdmin && (
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                {...getButtonLoadingProps(loading)}
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
                className={pageStyles.button}
                onClick={handleResetCache}
              >
                {'Reset Cache'}
              </Button>
            </Grid>
          )}
        </Grid>
      ) : (
        <p className={commonStyles.text}>
          {
            'Account details are handled by your Identity Provider. Contact them if you have any questions concerning your account.'
          }
        </p>
      )}
    </>
  );
};

export default EditSettingsPageView;
