import React, { useEffect, useMemo } from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { getExplanationFieldName } from 'util/Application';

interface ExplanationQuestionFormItemProps extends FormProps {
  parentFieldName: string;
  defaultValue?: string;
}

const ExplanationQuestionFormItem: React.FunctionComponent<ExplanationQuestionFormItemProps> = ({
  parentFieldName,
  required = false,
  defaultValue = '',
  disabled,
}) => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext();

  const fieldName = useMemo(() => getExplanationFieldName(parentFieldName), [parentFieldName]);

  useEffect(() => {
    return () => {
      resetField(fieldName, { defaultValue: '' });
    };
  }, [fieldName, resetField]);

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          required={required}
          placeholder={'Please add some more info'}
          {...getValidationProps(fieldName, errors)}
          disabled={disabled}
        />
      )}
      name={fieldName}
      control={control}
      rules={{
        required: getRequiredValidationRule('your answer', false, required),
      }}
      defaultValue={defaultValue || ''}
    />
  );
};
export default ExplanationQuestionFormItem;
