import classNames from 'classnames';
import React from 'react';
import StatusLabelTheme from 'store/configs/StatusLabelTheme';
import Chip from '@mui/material/Chip';
import ComponentProps from 'store/types/ComponentProps';

import styles from './StatusLabel.module.scss';

interface StatusLabelProps extends ComponentProps {
  theme?: StatusLabelTheme;
  status: string;
}

const StatusLabel: React.FunctionComponent<StatusLabelProps> = ({ theme = 'primary-theme', status, className }) => (
  <Chip label={status} className={classNames(styles.label, styles[theme], className)} data-testid={'status-label'} />
);

export default StatusLabel;
