const routes = {
  root: '#',

  redirect: '/redirectUrl',

  login: '/login',
  logout: '/logout',
  firstLogin: '/first-login',
  setPassword: '/set-password',

  home: '/home',

  support: '/support',

  partner: '/partner',
  partnerDetails: '/partner/details',

  addresses: '/addresses',

  memberDirectory: '/memberdirectory',

  membership: '/membership',
  enrollMembership: '/membership/enroll',
  subscriptions: '/subscriptions',
  enrollSubscription: '/subscriptions/enroll',
  renewSubscription: '/subscriptions/renew',
  subscriptionTerms: '/subscriptions/terms',

  personalPayments: '/payments/personal',
  personalPaymentDetails: '/payments/personal/details',
  makePersonalPayment: '/payments/personal/make',
  makePersonalSalesPayment: '/payments/personal/sales/make',

  manageDonations: '/donations/manage',

  expressInvoicePayment: '/payments/express/invoice',
  expressSaleOrderPayment: '/payments/express/salesorder',

  groupPayments: '/payments/group',
  makeGroupPayment: '/payments/group/make',
  paymentAccessSettings: '/payments/group/access',
  groupResponseApprove: '/payments/group/approve',
  groupResponseReject: '/payments/group/reject',

  companyPayments: '/payments/company',
  makeCompanyPayment: '/payments/company/make',
  makeCompanySalesPayment: '/payments/company/sales/make',

  viewPrivatePreferences: '/private/preferences',
  viewPrivateEventPreferences: '/private/event/preferences',

  profile: '/profile',
  profilePreferences: '/profile/preferences',
  profileAddresses: '/profile/addresses',
  profileRelationships: '/profile/relationships',
  profileEmployer: '/profile/employer',
  advocacy: '/profile/advocacy',

  communicationsPreferences: '/communications/preferences',

  committees: '/committees',
  publicCommittees: '/public/committees',
  publicCommitteesDetails: '/public/committees/details',

  chapters: '/chapters',

  myEvents: '/events/my',
  educationCertifications: '/education/certifications',
  educationTranscript: '/education/transcript',

  settings: '/settings',
  settingsAccount: '/settings/account',
  emailNotificationsSettings: '/settings/notifications',
  paymentMethodsSettings: '/settings/methods',

  companyManagement: '/company',
  companyInformation: '/company/information',
  companyAddresses: '/company/addresses',
  companyRequests: '/company/requests',
  companyAdvocacy: '/company/advocacy',

  applications: '/applications',
  createApplication: '/applications/create',
  editApplication: '/applications/edit',
  viewApplication: '/applications/view',
  viewPrivateApplication: '/applications/view/private',
  payApplication: '/applications/payment',
  applicationReviews: '/applications/reviews',
  editApplicationReview: '/applications/reviews/edit',
  viewApplicationReview: '/applications/reviews/view',

  donations: '/donations',
  makeDonation: '/donations/make',

  eventsCatalog: '/events/catalog',
  eventDetails: '/events/catalog/details',
  eventCart: '/events/catalog/cart',
  eventRegistrationUsersPrint: '/event/registration/users/print',

  productsCart: '/products/cart',

  engagementReport: '/engagement/report',
};

export const expressPaymentRoutes: string[] = [
  `${routes.expressInvoicePayment}/:expressCode`,
  `${routes.expressSaleOrderPayment}/:expressCode`,
];

export const profileRoutes: string[] = [
  routes.profile,
  routes.profilePreferences,
  routes.profileAddresses,
  routes.profileEmployer,
  routes.profileRelationships,
  routes.advocacy,
];

export const companyRoutes: string[] = [
  routes.companyManagement,
  routes.companyInformation,
  routes.companyAddresses,
  routes.companyRequests,
  routes.companyAdvocacy,
];
export const partnerRoutes: string[] = [routes.partner, routes.partnerDetails];
export const myEventsRoutes: string[] = [routes.myEvents, routes.educationCertifications, routes.educationTranscript];
export const companyPaymentsRoutes: string[] = [
  routes.companyPayments,
  routes.makeCompanyPayment,
  routes.makeCompanySalesPayment,
];
export const settingsRoutes: string[] = [
  routes.settingsAccount,
  routes.paymentMethodsSettings,
  routes.emailNotificationsSettings,
];

export const groupPaymentRoutes: string[] = [
  routes.groupPayments,
  routes.makeGroupPayment,
  routes.paymentAccessSettings,
  `${routes.groupResponseApprove}/:userId`,
  `${routes.groupResponseReject}/:userId`,
];
export const applicationsRoutes: string[] = [routes.applications, routes.applicationReviews];
export const editApplicationRoutes: string[] = [
  `${routes.editApplication}/:applicationId`,
  `${routes.viewApplication}/:applicationId`,
  `${routes.payApplication}/:applicationId`,
];
export const editApplicationReviewRoutes: string[] = [
  `${routes.editApplicationReview}/:reviewId`,
  `${routes.viewApplicationReview}/:reviewId`,
];

export const productsCartRoutes: string[] = [routes.productsCart, `${routes.productsCart}/:ids`];
export const publicEventsRoutes: string[] = [routes.eventsCatalog, `${routes.eventDetails}/:eventId`, routes.eventCart];
export const publicDonationsRoutes: string[] = [
  routes.donations,
  routes.makeDonation,
  `${routes.makeDonation}/:donationId`,
];
export const subscriptionRoutes: string[] = [
  routes.subscriptions,
  routes.enrollSubscription,
  `${routes.renewSubscription}/:subscriptionId`,
];

export const publicPreferencesRoutes: string[] = [
  `${routes.viewPrivatePreferences}/:alternateId`,
  `${routes.viewPrivateEventPreferences}/:alternateId`,
];

export default routes;
