import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import EditSettingsPageView from 'components/settings/EditSettingsPageView';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import SiteModule from 'store/enums/SiteModule';
import { getPageTitle } from 'util/Layout';
import { ConfigContext } from 'components/ConfigGuard';
import TabPanel from '@mui/lab/TabPanel';
import { TabContext } from '@mui/lab';
import { Tab, Tabs } from '@mui/material';
import { getActiveTabRoute, getActiveTabValue } from 'util/Route';
import { useHistory, useLocation } from 'react-router';
import routes, { settingsRoutes } from 'store/configs/Routes';
import ManagePaymentMethodsPageView from 'components/settings/ManagePaymentMethodsPageView';
import EmailNotificationsSettingsPageView from 'components/settings/EmailNotificationsSettingsPageView';

import commonStyles from 'styles/common.module.scss';

const SettingsPage: React.FunctionComponent = () => {
  const {
    sidebar,
    enabledModules,
    modulesSettings: {
      settings: { enableAccountSettings, enableEmailNotifications },
    },
  } = useContext(ConfigContext);
  const { userLoading = false } = useContext(UserContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const paymentsEnabled: boolean = useMemo(
    () => !!enabledModules.find((item) => item === SiteModule.Payments),
    [enabledModules]
  );
  const activeTab: string = useMemo(() => getActiveTabValue(pathname, settingsRoutes), [pathname]);

  const handleTabChange = useCallback(
    (_: any, value = '1') => {
      history.replace(getActiveTabRoute(value, settingsRoutes));
    },
    [history]
  );

  useEffect(() => {
    if (pathname === routes.settings) {
      if (enableAccountSettings) {
        history.replace(routes.settingsAccount);
      } else if (paymentsEnabled) {
        history.replace(routes.paymentMethodsSettings);
      } else {
        history.replace(routes.emailNotificationsSettings);
      }
    }
  }, [history, pathname, enableAccountSettings, paymentsEnabled]);

  return (
    <>
      <h1 className={commonStyles.pageTitle}>{getPageTitle(sidebar, SiteModule.Settings) || 'Account Settings'}</h1>
      <Spinner loading={userLoading} transparent={false}>
        <TabContext value={activeTab}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor={'primary'}
            textColor={'primary'}
            variant={'scrollable'}
          >
            {enableAccountSettings && <Tab value={'1'} label={'Account Settings'} className={commonStyles.tab} />}
            {paymentsEnabled && <Tab value={'2'} label={'Manage Saved Payment'} className={commonStyles.tab} />}
            {enableEmailNotifications && (
              <Tab value={'3'} label={'Manage Email Notifications'} className={commonStyles.tab} />
            )}
          </Tabs>
          {enableAccountSettings && (
            <TabPanel className={commonStyles.tabPanel} value={'1'}>
              <Spinner loading={userLoading} transparent={false}>
                <EditSettingsPageView />
              </Spinner>
            </TabPanel>
          )}
          {paymentsEnabled && (
            <TabPanel className={commonStyles.tabPanel} value={'2'}>
              <Spinner loading={userLoading} transparent={false}>
                <ManagePaymentMethodsPageView />
              </Spinner>
            </TabPanel>
          )}
          {enableEmailNotifications && (
            <TabPanel className={commonStyles.tabPanel} value={'3'}>
              <Spinner loading={userLoading} transparent={false}>
                <EmailNotificationsSettingsPageView />
              </Spinner>
            </TabPanel>
          )}
        </TabContext>
      </Spinner>
    </>
  );
};
export default SettingsPage;
