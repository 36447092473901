import React, { useContext } from 'react';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import MemberDirectoryPageView from 'components/memberDirectory/MemberDirectoryPageView';
import classNames from 'classnames';
import Sidebar from 'components/layout/Sidebar';
import { useWindowSize } from 'util/Window';
import PublicHeader from 'components/layout/PublicHeader/PublicHeader';

import commonStyles from 'styles/common.module.scss';
import styles from './MemberDirectoryPage.module.scss';

const MemberDirectoryPage: React.FunctionComponent = () => {
  const { userLoading = false } = useContext(UserContext);
  const { isMobile } = useWindowSize();

  return (
    <Spinner loading={userLoading} transparent={false}>
      <div className={styles.wrapper}>
        <div className={classNames(styles.sidebar, { [commonStyles.hidden]: isMobile })}>
          <Sidebar alwaysCollapsed={true} />
        </div>
        <div className={'rootContent'}>
          <PublicHeader />
          <MemberDirectoryPageView />
        </div>
      </div>
    </Spinner>
  );
};
export default MemberDirectoryPage;
